import React from "react";
import HeadLines from "../HeadLines";
import "../../commonStyle.css";
import styles from "./styles.module.css";
const TwoLineWidget = (props) => {
  const {
    title,
    subtitle,
    titleClassName,
    className,
    subtitleClassName,
    heading1ClassName = "colorDark9",
    heading2ClassName = `${styles["mt-4px"]} colorDark5`,
    onClickTitle = () => {},
  } = props;
  return (
    <div className={className}>
      <HeadLines
        onClick={onClickTitle}
        text={title}
        className={`${heading1ClassName} ${titleClassName}`}
      />
      <HeadLines
        text={subtitle}
        className={`${heading2ClassName} ${subtitleClassName}`}
      />
    </div>
  );
};

export default TwoLineWidget;
