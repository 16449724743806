import React from "react";
import style from "./ActiveLoanComponent.module.css";
import HeadLines from "../../../../CommonComponents/HeadLines";
import { ProgressBar } from "../../../../AuctionComponents/Components/Common/ProgressBar";
import { isMobile } from "../../../../helperFunc";
import MultiProgressbar from "../MultiProgressBar/MultiProgressBar";
function ActiveLoanComponent({ loanData = {} }) {
  const { sr, disbursed, pledged, principle, released, due, qtyPledged } =
    loanData || {};
  return (
    <div className={`${style.loanBox} stdFontStyle margin-top16`}>
      <div className="d-flex justify-content-space-between align-items-center width-100">
        <div className={`${style.width70}`}>
          <HeadLines
            text={`SR: ${sr}`}
            className={`${
              isMobile
                ? "font-size12 line-height14 letterSpacing005"
                : "font-size14 line-height16 letterSpacing005"
            } colorDark1 font-weight400`}
          />
          <div className="margin-top16">
            <MultiProgressbar ltpLimit={0} />
          </div>

          <div className="d-flex align-items-center justify-content-space-between">
            <div>
              <HeadLines
                className={`${
                  isMobile
                    ? "font-size10 line-height12 letterSpacing005"
                    : "font-size12 line-height14 letterSpacing005"
                } colorAccent3 font-weight700`}
                text={`Principle Paid: ${principle}`}
              />
              <HeadLines
                className={`${
                  isMobile
                    ? "font-size10 line-height12 letterSpacing005"
                    : "font-size12 line-height14 letterSpacing005"
                } colorAccent3 font-weight700`}
                text={`Quantity Released: ${released}`}
              />
            </div>
            <div>
              <HeadLines
                text={`Principle Due: ${due}`}
                className={`${
                  isMobile
                    ? "font-size10 line-height12 letterSpacing005"
                    : "font-size12 line-height14 letterSpacing005"
                } colorDark5 font-weight700`}
              />
              <HeadLines
                text={`Current Qty Pledged: ${qtyPledged}`}
                className={`${
                  isMobile
                    ? "font-size10 line-height12 letterSpacing005"
                    : "font-size12 line-height14 letterSpacing005"
                } colorDark5 font-weight700`}
              />
            </div>
          </div>
        </div>

        <div className={style["lineCss"]}></div>

        <div>
          <div className="d-flex flex-d-col align-items-center justify-content-center">
            <HeadLines
              text={` ${disbursed}`}
              className={`font-size16 line-height19 letterSpacing005 colorAccent3 font-weight700 margin-top16`}
            />
            <HeadLines
              text={`Disbursed Amount`}
              className={`${
                isMobile
                  ? "font-size10 line-height12 letterSpacing005"
                  : "font-size12 line-height14 letterSpacing005"
              } colorAccent3 font-weight400 margin-top4`}
            />
            <HeadLines
              text={` ${pledged}`}
              className={`font-size16 line-height19 letterSpacing005 colorAccent3 font-weight700 margin-top16`}
            />
            <HeadLines
              text={`Quantity Pledged`}
              className={`${
                isMobile
                  ? "font-size10 line-height12 letterSpacing005"
                  : "font-size12 line-height14 letterSpacing005"
              } colorAccent3 font-weight400 margin-top4`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveLoanComponent;
