import React from "react";
import Card from "../CommonComponents/Card";
import CoverPic from "../CommonComponents/CoverPic";
import HeadLines from "../CommonComponents/HeadLines";
import ProfilePic from "../CommonComponents/ProfilePic";
import {
  BlueRightArrow,
  arrow,
  docIcon,
  greenTick,
  heartIcon,
  infoIcon,
  quoteIcon,
  stockIcon,
  storeIcon,
} from "../icon_assets";
import s from "./styles.module.css";
import "../commonStyle.css";
import StatusBar from "./StatusBar";

import OptionItem from "./OptionItem";
import { useHistory } from "react-router";
import { getUserImages } from "../../../../_services/Arya2.0/Profile";
import useUserDetails from "../CustomHooks/useUserDetails";
import { useEffect } from "react";
import { useState } from "react";
import { updateUserImages } from "../../../../_services/Arya2.0/Profile/api";
import Registration from "../Registration/Registration";
import Loader from "../CommonComponents/Loader/Loader";
import { isMobile } from "../helperFunc";
import { profileLinkEventsObj } from "./constants";
import { eventTracker } from "../GATracker3.0/eventTracker";
import { titleCase } from "../CommonFunctions/Function";

const options = [
  {
    icon: docIcon,
    text: "Documents",
    link: "/document-center",
  },
  {
    icon: heartIcon,
    text: "Favourites",
    link: "/my-wishlist?id=0",
  },
  {
    icon: stockIcon,
    text: "Delivery Orders",
    link: "/release-stock-log",
  },
  {
    icon: quoteIcon,
    text: "My Quotes",
    link: "/my-quotes",
  },
  // {
  //   icon: storeIcon,
  //   text: "My Store",
  //   link: "/my-store",
  // },
];

const ProfileCard = (props) => {
  const {
    cardClassName,
    showOptions = true,
    showKyc = true,
    childrenData = [],
    kycNote = "KYC Pending, complete soon",
    designation = "",
    showKycArrow = true,
    switchFunc = () => {},
    onCoverEditButtonClick,
    onProfileEditButtonClick,
    profileVerified = true,
    statusBarClassName = "",
    statusBarClick = () => null,
    title = "",
    designationClassName = "",
  } = props;
  const history = useHistory();
  const userDetails = useUserDetails();
  const [coverPic, setCoverPic] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getUserImagesFromAPI = async () => {
    const res = await getUserImages();
    if (res?.status_code == 200) {
      setCoverPic(res.data.cover_image);
      setProfilePic(res.data.profile_image);
    }
  };

  useEffect(() => {
    if (userDetails?.userLoggedIn) getUserImagesFromAPI();
  }, [userDetails]);

  // const updateProfilePic = async (img) => {
  //   let data = {
  //     id: userDetails?.id,
  //     type: "profile",
  //     multimedia: {
  //       id: userDetails?.profile_multimedia_id ?? null,
  //       file_name: img,
  //     },
  //   };
  //   const res = await updateUserImages(data);
  //   if (
  //     res.status_code == 200 &&
  //     Array.isArray(res.data) &&
  //     res.data.length > 0
  //   ) {
  //     setProfilePic(img);
  //   }
  // };
  const updateProfilePic = async (imgFile) => {
    // let data = {
    //   // id: userDetails?.id,
    //   type: "cover",
    //   image: imgFromData,
    //   multimedia_id: userDetails?.cover_multimedia_id ?? null,
    // };

    let formData = new FormData();
    formData.append("type", "profile");
    formData.append("image", imgFile);
    formData.append(
      "multimedia_id",
      userDetails?.profile_multimedia_id ?? null
    );

    try {
      setIsLoading(true);
      const res = await updateUserImages(formData);
      if (res.status_code == 200 && res.data.file_name) {
        setProfilePic(res.data.file_name);
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  const updateCoverPic = async (imgFile) => {
    // let data = {
    //   // id: userDetails?.id,
    //   type: "cover",
    //   image: imgFromData,
    //   multimedia_id: userDetails?.cover_multimedia_id ?? null,
    // };

    let formData = new FormData();
    formData.append("type", "cover");
    formData.append("image", imgFile);
    formData.append("multimedia_id", userDetails?.cover_multimedia_id ?? null);

    try {
      setIsLoading(true);
      const res = await updateUserImages(formData);
      if (res.status_code == 200 && res.data.file_name) {
        setCoverPic(res.data.file_name);
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {show && <Registration show={show} setShow={setShow} />}
      <Loader isLoading={isLoading} />
      <Card
        className={`
                ${s["margin-top-n31"]} 
                ${isMobile && s["card-shadow"]} 
                ${
                  isMobile
                    ? `margin-left16 
                      margin-right16 
                      margin-bottom16`
                    : `margin-bottom20`
                } 
                bg-white 
                boxRadius8
                ${cardClassName}
                `}
      >
        <CoverPic
          imageSource={coverPic}
          onEditButtonClick={onCoverEditButtonClick}
          setCoverImage={updateCoverPic}
          editIconWidth={isMobile ? undefined : "32"}
        />
        <ProfilePic
          onEditButtonClick={onProfileEditButtonClick}
          className={`m-auto 
                    position-relative 
                    ${s["profile-pic-class"]} 
                    ${s["width-fit-content"]}`}
          imageSource={profilePic}
          setProfileImage={updateProfilePic}
          editIconWidth={isMobile ? undefined : "32"}
        />
        <div className={`${s["mt-n30px"]}`}>
          <div
            className={`
                      
                      ${isMobile ? `margin-top12` : `margin-top16`}
                      d-flex 
                      flex-column 
                      gap4
                    `}
          >
            <HeadLines
              className={`
                      font-weight600 
                      ${
                        isMobile
                          ? `font-size14
                      line-height16`
                          : `font-size24
                      line-height28
                      letterSpacing001`
                      }
                      text-center`}
            >
              {title ? `${titleCase(title)}` : ""}
              {profileVerified && (
                <img
                  width={isMobile ? undefined : 18}
                  src={greenTick}
                  className={`${s["ml2"]} 
                  ${isMobile ? s["v-a-top"] : `mt-n1 ${s["v-a-mid"]}`}
                  `}
                />
              )}
            </HeadLines>
            {designation && (
              <HeadLines
                className={`
                      font-weight400
                     ${
                       isMobile
                         ? `font-size12
                            line-height14
                            letterSpacing025`
                         : `font-size20
                            line-height23
                            letterSpacing05`
                     }
                      textCenter
                      ${s["color293A3A"]}
                      ${designationClassName}
                        `}
                text={designation}
              />
            )}
            {showKyc && (
              <StatusBar
                className={`
                      margin-top4
                      ${
                        isMobile
                          ? `font-size10 ${s["gap7_5"]} ${s["line-height11"]}`
                          : `${s["col-gap20"]} font-weight400 font-size14 line-height16 letterSpacing05 align-items-center`
                      }
                      
                      ${true ? s["kyc-pending"] : ""}
                      ${statusBarClassName}
                        `}
                rightArrow={
                  showKycArrow && (
                    <img
                      src={arrow}
                      width={isMobile ? 2.5 : 5}
                      height={isMobile ? 5 : undefined}
                      className={`${s["v-a-mid"]}`}
                    />
                  )
                }
                leftIcon={infoIcon}
                text={kycNote}
                onClick={() => setShow(true)}
              />
            )}
          </div>
          {!!childrenData?.length && (
            <div className="d-flex justify-content-center margin-top16">
              <div
                onClick={switchFunc}
                className={`d-flex align-items-center cursor-pointer justify-content-space-between ${s["switchTag"]}`}
              >
                <HeadLines
                  text="Switch Profile"
                  className={`${
                    isMobile
                      ? "font-size12 line-height14"
                      : "font-size14 line-height16"
                  } textColorBlue font-weight400`}
                />
                <img src={BlueRightArrow} className="margin-left4" />
              </div>
            </div>
          )}

          <div className={`${s["h16"]}`} />
          {showOptions && (
            <Card
              className={`
                    ${
                      isMobile
                        ? `${s["border-top-light-grey"]} borderRadiusBottom padding-top16 padding-bottom16  padding-left13 padding-right13`
                        : `d-profile-options mx-auto ${s["boxRadius10"]} ${s["d-card"]}`
                    }
                    d-flex 
                    justify-content-between
                    ${s["options-bar"]}`}
            >
              {options.map((el) => (
                <OptionItem
                  iconClassName={`${s["option-icons"]}`}
                  onClick={() => {
                    eventTracker("event", profileLinkEventsObj[el.text], {});
                    history.push(el.link);
                  }}
                  className={`cursor-pointer ${s["option-item"]}`}
                  icon={el.icon}
                  text={
                    <HeadLines
                      className={`
                  ${
                    isMobile
                      ? `font-size10 line-height12 colorDark7 letterSpacing004`
                      : `font-size16 line-height19 colorDark7 letterSpacing05
                  `
                  }
                  
                 `}
                      text={el.text}
                    />
                  }
                />
              ))}
            </Card>
          )}
        </div>
      </Card>
    </>
  );
};

export default ProfileCard;
