import React from "react";
import styles from "./flexComponentMobile.module.css";
import HeadLines from "../../../../CommonComponents/HeadLines";

const FlexComponentMobile = ({ leftTitle, rightNumber = null }) => {
  return (
    <div className={`${styles.container} padding16`}>
      <div className={styles.leftTitle}>
        <HeadLines text={leftTitle} />
      </div>
      {rightNumber && (
        <div className={styles.rightNumber}>
          <HeadLines text={rightNumber} />
        </div>
      )}
    </div>
  );
};

export default FlexComponentMobile;
