import React from "react";
import Button from "../Button";
import InputText from "../InputText";
import "../../commonStyle.css";
import { verifiedCheckIcon as vIcon } from "../../icon_assets";
import s from "./styles.module.css";
import Title from "./Title";
import LoaderCircle from "../Loader/LoaderCircle/LoaderCircle";
import HeadLines from "../HeadLines";

const InputFieldWithTitle = (props) => {
  const {
    title,
    disabled,
    className,
    inputClassName,
    disableInputClassName,
    errorInputClassName,
    verifiedClassName,
    errorClassName,
    errorIconClassName,
    showError,
    errorIcon,
    titleClassName,
    showVerifyText,
    showVerifyTextClassName,
    isVerified = false,
    showVerifiedCheckIcon = false,
    verifiedCheckIcon = vIcon,
    verifyIconClassName = "",
    verifyClicked = () => null,
    value = "",
    onChange = () => null,
    placeholder = "",
    isCompulsory = false,
    compulsoryStarClassName = "",
    name = "",
    maxLength,
    textToShowOnly = "",
    inputParentClassName = "",
    verifyLoader = false,
    rightInputFixedTextClassName,
    rightInputFixedText = "",
    leftInputFixedText = "",
    type = "text",
    onClick = () => null,
    autoComplete,
    onBlur = () => null,
    verifyText = "VERIFY",
    disableIfVerified = true,
    inputRef = null,
    marginCSS = "margin-bottom16",
    showRupeeSymbol = false,
    showRupeeSymbolClassName = "",
    verifyLoaderClass,
    showInfotext = "",
    showInfotextClassName,
    verifiedCheckIconOnClick = () => {},
    errorTextClassName,
  } = props;

  return (
    <div className={`positionRelative ${marginCSS} ${className}`}>
      {title && (
        <Title
          titleClassName={titleClassName}
          title={title}
          isCompulsory={isCompulsory}
          compulsoryStarClassName={`asterisk ${compulsoryStarClassName}`}
        />
      )}
      <div className={`positionRelative ${inputParentClassName}`}>
        <InputText
          type={type}
          maxLength={maxLength}
          placeholder={placeholder}
          disabled={disabled || (disableIfVerified ? isVerified : false)}
          className={inputClassName}
          disableInputClassName={disableInputClassName}
          errorIconClassName={errorIconClassName}
          errorInputClassName={errorInputClassName}
          errorClassName={errorClassName}
          showError={showError}
          errorIcon={errorIcon}
          isVerified={isVerified}
          verifiedClassName={verifiedClassName}
          value={value}
          onChange={onChange}
          name={name}
          rightInputFixedTextClassName={rightInputFixedTextClassName}
          rightInputFixedText={rightInputFixedText}
          leftInputFixedText={leftInputFixedText}
          onClick={onClick}
          autoComplete={autoComplete}
          onBlur={onBlur}
          inputRef={inputRef}
          errorTextClassName={errorTextClassName}
        />
        {textToShowOnly ?? ""}
        {showInfotext && (
          <HeadLines
            text={showInfotext}
            className={`${
              showInfotextClassName
                ? showInfotextClassName
                : " font-weight400 font-size12 line-height12 letterSpacing0125 colorDark9"
            } margin-top6`}
          />
        )}
        {!isVerified && showVerifyText && (
          <Button
            className={`${s["verify-text-style"]} ${
              value ? "" : "screen-disable"
            } ${showVerifyTextClassName}`}
            title={verifyText}
            onClick={value ? verifyClicked : ""}
          />
        )}
        {verifyLoader && (
          <LoaderCircle
            spinnerClassName={`${s["verify-loader"]} ${verifyLoaderClass}`}
            circleColor={`#d9d9d9`}
            // circleThickness={circleThickness}
          />
        )}
        {showVerifiedCheckIcon && (
          <img
            onClick={verifiedCheckIconOnClick}
            alt=""
            src={verifiedCheckIcon}
            className={`${s["verified-icon-style"]} ${verifyIconClassName}`}
          />
        )}
        {showRupeeSymbol && <div className={showRupeeSymbolClassName}>₹</div>}
      </div>
    </div>
  );
};

export default InputFieldWithTitle;
