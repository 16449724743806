//Login flow
export const LOGIN_CTA_CLICKED = "login_cta_clicked";
export const LOGIN_POPUP_APPEARING = "login_popup_appeared";
export const LOGIN_POPUP_CLOSED = "login_popup_closed";
export const VERIFY_NUMBER_CTA_CLICKED = "get_otp_clicked";
export const RESEND_OTP_VIA_SMS = "resend_otp_via_sms_clicked";
export const RESEND_OTP_VIA_CALL = "resend_otp_via_call_clicked";
export const CHANGE_NUMBER = "change_number_clicked";
export const LOGIN_AFTER_OTP_SUCCESS = "login_successful";
export const LOGIN_AFTER_OTP_FAIL = "login_unsuccessful";

//Post Trade Requirement

export const POST_TRADE_REQUIREMENT_CLICKED = "post_trade_requirement_clicked";
export const POST_REQUIREMENT_FORM_APPEARS = "requirement_form_appeared";
export const REQUIREMENT_SUBMIT_CLICKED = "requirement_submit_clicked";

// Requirement detail page

export const REQUIREMENT_CARD_CLICKED = "requirement_card_clicked";
export const SUBMIT_QUOTE_CLICKED = "quote_clicked";
export const CALL_BUYER_SELLER_CLICKED = "call_cta_clicked";
export const SHARE_ICON_CLICKED = "share_icon_clicked";
export const QUOTE_FORM_OPEN = "quote_form_opened";
export const QUOTE_FORM_SUBMITTED = "quote_form_submitted";

//Warehouse

export const BOOK_WAREHOUSE_BANNER_CLICKED = "warehouse_banner_clicked";
export const WAREHOUSE_LISTING_SEARCH_CLICKED = "warehouse_search_clicked";
export const WAREHOUSE_CARD_CLICKED = "warehouse_card_clicked";
export const BOOK_NOW_CTA_CLICKED = "book_now_clicked";
export const BOOK_NOW_FORM_OPEN = "book_now_form_opened";
export const BOOK_NOW_FORM_SUBMITTED = "book_now_form_submitted";

//Release commodity
export const RELEASE_STOCK_CLICKED_OLD_NEW = "release_stock_clicked";
export const ENTER_QUANTITY_OLD_NEW = "release_quantity_submitted";
export const PAY_NOW_CLICKED_OLD_NEW = "release_pay_now_clicked";
export const QUICK_PAY_OTHER_METHOD_CLICKED = "quick_pay_other_methods_clicked";
export const SELECT_CONTRACTS_NEW = "release_select_contract";
export const QUANTITY_FILLED = "quantity_filled";
export const PAYMENT_METHOD_SELECTION = "payment_method_pay_now_clicked";
export const PAYMENT_SUCCESSFUL = "Payment method successful/unsuccessful";
export const CONSENT_APPEARED = "consent_appeared";
export const CONSENT_ACCEPTED = "consent_accepted";

//Products Certified/Noncertified

export const PRODUCT_CLICKED = "product_clicked";
export const CONTACT_SELLER_CLICKED = "product_contact_seller";
export const ENQUIRY_FORM_OPENED = "enquiry_form_opened";
export const ENQUIRY_FORM_SUBMITTED = "enquiry_form_submitted";

//Blockchain
export const TOKEN_VIEWED = "token_detail_viewed";
export const TOKEN_ACCEPTED = "token_accepted";
export const TOKEN_REJECTED = "token_rejected";

// MANDI BHAV

export const MANDI_PRICE_SEARCHED = "mandi_price_searched";
export const MANDI_PRICE_CARD_CLICKED = "mandi_price_card_clicked";

//PAGESPEED
export const FIRST_CONTENTFUL_PAINT = "fcp";
export const LARGEST_CONTENTFUL_PAINT = "lcp";

//VIEW LIVE FEED

export const VIEW_LIVE_FEED = "view_feed_clicked";
export const FEED_VIEWED = "feed_viewed";

export const CALL_BACK_BANNER = "request_callback_clicked";

export const WHO_WE_SERVE = "who_we_serve_page_clicked";

export const SERVICES_CARD_CLICKED = "services_card_clicked";

//VERNACULAR
export const CHANGE_LANGUAGE = "change_language";

export const QUICK_LINK_CLICKED = "quick_link_clicked";

export const DOWNLOAD_APP_CLICKED = "download_app_clicked";

export const CREATE_ACCOUNT_CLICKED = "create_account_clicked";

export const BANNER_CLICKED = "banner_clicked";

export const LANG_PREF_CLICKED = "lang_pref_ban_clicked";

//FOOTER NAV
export const FOOTER_NAV_HOME = "footer_nav_home";
export const FOOTER_NAV_STORAGE = "footer_nav_storage";
export const FOOTER_NAV_TRADE = "footer_nav_trade";
export const FOOTER_NAV_FINANCE = "footer_nav_finance";
export const FOOTER_NAV_LOGIN = "footer_nav_login";
export const FOOTER_NAV_PROFILE = "footer_nav_profile";

//NOTIFICATION

export const NOTIFICATION_CLICKED = "notification_clicked";
export const NOTIFICATION_APPEARED = "notification_appeared";
export const NOTIFICATION_CLOSED = "notification_closed";

//PROFILE

export const DOCUMENT_CLICKED = "document_clicked";
export const FAVORITES_CLICKED = "favorites_clicked";
export const RELEASE_LOG_CLICKED = "release_log_clicked";
export const LOGOUT_CLICKED = "logout_clicked";
export const MY_QUOTES_CLICKED = "my_quotes_clicked";
export const MY_STORE_CLICKED = "my_store_clicked";

//Notification

export const LANG_PREFERENCE_CLICKED = "lang_selected";

export const addInfoNotificationValue = {
  LOAN_DISBURSEMENT: "disbursement request",
  RELEASE_STOCK: "complete payment",
  LEAD_QUOTATION: "quote received",
  USER_KYC: "compelete kyc",
  SPOT_CONTRACT_CREATION: "new contract",
  COMMODITY_INWARD: "new commodity inward",
};

export const exploreServicesEventObject = {
  Trade: {
    firstHeading: "Post Trade",
    secondHeading: "Create Auction",
  },
  Storage: {
    firstHeading: "Find Warehouse",
    secondHeading: "My Store",
  },
  Finance: {
    firstHeading: "Loan Against Commodity Form",
    secondHeading: "Finance",
  },
};

export const bannersEventObject = {
  "/blockchain": "Blockchain",
  "/post-your-requirements": "Post Trade",
  "/produce-estimate/login": "Produce Estimate",
  "https://prakshep.arya.ag/": "Prakshep",
  "https://aryadhan.in/": "Aryadhan",
};

export const footerTitleEventObject = {
  Finance: FOOTER_NAV_FINANCE,
  Trade: FOOTER_NAV_TRADE,
  Storage: FOOTER_NAV_STORAGE,
  Home: FOOTER_NAV_HOME,
};
