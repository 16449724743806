import React from "react";
import Image from "react-image-webp";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { URL_CONFIG } from "../../../../../_constants/Config/URL_CONFIG";
import HeadLines from "../../CommonComponents/HeadLines";
import { servicesArray, GetToKnowUs } from "./constant";
import style from "./style.module.css";
import call from "./../../DesktopImages/Home/call.svg";
import mail from "./../../DesktopImages/Home/mail.svg";
import appStoreImage from "./../../DesktopImages/Home/appStoreImage.png";
import playStoreImage from "./../../DesktopImages/Home/playStoreImage.png";
import { locationIconWhite } from "../../icon_assets";

const TopPart = (props) => {
  const history = useHistory();
  return (
    <div className="d-flex">
      <div className={`${style["flex33"]} padding-right16 padding-top12`}>
        <div
          className={`backgroundWhite boxRadius4 padding-bottom4 padding-top4 margin-bottom16 padding-left8 padding-right8 ${style["max-width-fit"]}`}
        >
          <Image
            src={URL_CONFIG.IMG_URL + "/home/arya-logo.png"}
            webp={URL_CONFIG.IMG_URL + "/home/arya-logo.webp"}
            alt="logo"
            className={`${style["aryaLogoCSS"]} cursor-pointer`}
            onClick={() => {
              history.push("/");
            }}
          />
        </div>
        <HeadLines
          text="India’s largest integrated grain commerce platform. We help buyers and sellers find the best prices for their commodities."
          className={
            "colorLightGrey font-size14 font-weight14 font-weight400 line-height19 letterSpacing05 padding-bottom16"
          }
        />

        {/* <div className={`d-flex ${style["align-items-stretch"]} margin-top16`}> */}
        <div className="margin-top16">
          <HeadLines
            className={`font-size14 line-height19 letterSpacing05 font-weight500 textColorWhite margin-bottom12 ${style["mr-minus-16"]}`}
            text="Download arya.ag app"
          />
                    <div className="d-flex gap12">
            <img
              className={`cursor-pointer ${style["store-icon"]}`}
              src={playStoreImage}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=arya.ag",
                  "_blank",
                  "noopener"
                )
              }
              alt=""
            />
            <img
              className={`cursor-pointer ${style["store-icon"]}`}
              alt=""
              src={appStoreImage}
              onClick={() =>
                window.open(
                  "https://apps.apple.com/app/arya-marketplace/id1621681339",
                  "_blank",
                  "noopener"
                )
              }
            />
          </div>
        </div>
      </div>
      <div
        className={` d-flex justify-content-between ${style["flex20"]}  padding-right16 padding-left16 margin-left16 ${style["border-left"]}`}
      >
        <div>
          <HeadLines
            className="font-size14 line-height19 letterSpacing05 font-weight500 margin-top16 padding-left16 padding-bottom16  textColorWhite "
            text={"Quick Links"}
          />
          <div className="padding-bottom16 padding-left16">
            {GetToKnowUs.map((item) => {
              return (
                <Link to={item.onClick}>
                  <HeadLines
                    className="colorLightGrey font-size12 line-height16 font-weight400 letterSpacing05 padding-bottom12"
                    text={item.title}
                  />
                </Link>
              );
            })}
            <div className="padding-bottom16">
              {servicesArray.map((item) => {
                if(item?.differentTab){
                    return <div className="cursor-pointer" onClick={()=>{
                                window.open(
                                  item.onClick,
                                  "_blank"
                                )
                              }}>
                              <HeadLines
                                className="colorLightGrey font-size12 line-height16 font-weight400 letterSpacing05 padding-bottom12"
                                text={item.title}
                              />
                            </div>
                }
                return (
                  <Link to={item.onClick}>
                    <HeadLines
                      className="colorLightGrey font-size12 line-height16 font-weight400 letterSpacing05 padding-bottom12"
                      text={item.title}
                    />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${style["flex20"]}  padding-right16 padding-left16 margin-left16 ${style["border-left"]}`}
      >
        <HeadLines
          className="font-size14 line-height19 letterSpacing05 padding-left16 font-weight500 margin-top16 padding-bottom16  textColorWhite "
          text={"Contact"}
        />
        <div className="padding-bottom16 padding-left16">
          <div className="d-flex align-items-center padding-bottom12">
            <img src={mail} alt="" />
            <HeadLines
              className="colorLightGrey font-size12 padding-left8  line-height16 font-weight400 letterSpacing05 "
              text={"contact@arya.ag"}
            />
          </div>
          <a href={"tel:+91 9015260260"} className="d-flex align-items-center">
            <img src={call} alt="" />
            <HeadLines
              className="colorLightGrey padding-left8 font-size14 line-height16 font-weight400 letterSpacing05 "
              text={"+919015260260"}
            />
          </a>

          <div className="d-flex padding-bottom12 align-items-start margin-top8">
            <img src={locationIconWhite} className="margin-left4" alt="" />
            <HeadLines
              className="colorLightGrey margin-left12 font-size12 padding-left8  line-height16 font-weight400 letterSpacing05 "
              text={"H-82, Sector-63, Noida, Uttar Pradesh, India 201301"}
            />
          </div>
        </div>
        {/* <HeadLines
          className={`${style["width-200"]} font-size14 line-height19 letterSpacing05 padding-left16  font-weight500 margin-top16 padding-bottom16  textColorWhite `}
          text={"Grievance Redressal Officer"}
        />
        <div className="padding-bottom16 padding-left16">
          <div className="d-flex align-items-center padding-bottom12">
            <img src={profile} />
            <HeadLines
              className="colorLightGrey font-size12 padding-left8  line-height16 font-weight400 letterSpacing05 "
              text={"Nitendra Gupta"}
            />
          </div>
          <a href={"tel:0120-4859319"} className="d-flex align-items-center">
            <img src={call} />
            <HeadLines
              className="colorLightGrey font-size12 padding-left8  line-height16 font-weight400 letterSpacing05 "
              text={"0120-4859319"}
            />
          </a>
        </div> */}
      </div>
      <div
        className={`${style["flex27"]}  padding-right16 padding-left16 margin-left16 ${style["border-left"]}`}
      >
        <HeadLines
          className="font-size14 line-height19 letterSpacing05 padding-left16 font-weight500 margin-top16 padding-bottom16  textColorWhite "
          text={"Our Entities"}
        />
        <HeadLines
          className=" font-size12 line-height14 letterSpacing05 colorLightGrey padding-left16 font-weight500 "
          text={"Arya Collateral Warehousing Services Pvt. Ltd"}
        />
        <HeadLines
          className=" font-size12 line-height14 letterSpacing05 padding-left16 margin-top8 font-weight400 colorLightGrey "
          text={"For queries, contact:"}
        />
        <HeadLines
          className=" font-size12 line-height14 letterSpacing05 padding-left16 margin-top4 font-weight400 colorLightGrey "
          text={"Prakash Shukla"}
        />
        <HeadLines
          className=" font-size12 line-height14 margin-top4 letterSpacing05 padding-left16 font-weight400 colorLightGrey "
          text={"Contact Number: +91-120 4859355"}
        />
        <HeadLines
          className=" font-size12 line-height14 margin-top16 letterSpacing05  padding-left16 font-weight500 colorLightGrey "
          text={"Aryadhan Financial Solutions Private Limited"}
        />
        <HeadLines
          className=" font-size12 line-height14 letterSpacing05 padding-left16 margin-top8 font-weight400 colorLightGrey "
          text={"Grievance Redressal Officer:"}
        />
        <HeadLines
          className=" font-size12 line-height14 letterSpacing05 padding-left16 margin-top4 font-weight400 colorLightGrey "
          text={"Nitendra Gupta"}
        />
        <HeadLines
          className=" font-size12 line-height14 margin-top4 letterSpacing05 padding-left16 font-weight400 colorLightGrey "
          text={"Contact Number: 0120-4859319"}
        />
        <HeadLines
          className=" font-size12 line-height14 margin-top16 letterSpacing05 padding-left16 font-weight500 colorLightGrey "
          text={"Aryatech Platforms Private Limited"}
        />
        <HeadLines
          className=" font-size12 line-height14 letterSpacing05 padding-left16 margin-top8 font-weight400 colorLightGrey "
          text={"For queries, contact:"}
        />
        <HeadLines
          className=" font-size12 line-height14 letterSpacing05 padding-left16 margin-top4 font-weight400 colorLightGrey "
          text={"Prakash Shukla"}
        />
        <HeadLines
          className=" font-size12 line-height14 margin-top4 letterSpacing05 padding-left16 font-weight400 colorLightGrey "
          text={"Contact Number: +91-120 4859355"}
        />
      </div>
    </div>
  );
};
export default TopPart;
