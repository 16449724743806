import i18next from "i18next";
import moment from "moment";
import React, { Suspense, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { LoanApprovalAPI } from "../../../../_services/Arya2.0/Finance/LAC";
import { LoginServices } from "../../../../_services/Arya2.0/Login/LoginServices";
import Card from "../CommonComponents/Card";
import HeadLines from "../CommonComponents/HeadLines";
import useIsBanker from "../CustomHooks/useIsBanker";
import useIsUserLoggedIn from "../CustomHooks/useIsUserLoggedIn";
import useUserLanguage from "../CustomHooks/useUserLanguage";

import { setUserInfo } from "../Redux/UserInfo/actions";
import BackToTop from "./BackToTop";
import IconsSvg from "./IconsSvg";
import style from "./style.module.css";

import { eventTracker } from "../../../../_Pages/_webapp/arya2.0/GATracker3.0/eventTracker";
import { getQueryParam } from "../helperFunc";
import { useTranslation } from "react-i18next";
import { languageOptions } from "../Profile/constants";
import {
  getLanguageFromStore,
  setUserLanguage,
} from "../Redux/UserLang/actions";
import useUserDetails from "../CustomHooks/useUserDetails";
import { BackToTopFunc } from "../CommonFunctions/Function";

import {
  FOOTER_NAV_LOGIN,
  FOOTER_NAV_PROFILE,
  footerTitleEventObject,
} from "../GATracker3.0/constants";
import { titleCase } from "../CommonFunctions/Function";
import { setAryaShaktiMobileInfo } from "../Redux/AryaShaktiInfo/actions";
import useUserIP from "../CustomHooks/useUserIP";
import GetInTouchPopupForm from "../Trade/Listing/HomepageRevamp4.0/GetInTouchPopUp/GetInTouchPopupForm";
const Registration = React.lazy(() => import("../Registration/Registration"));
const Login = React.lazy(() => import("../Login/Login"));

const ApprovalSuccessPopUp = React.lazy(() =>
  import("../Finance/LAC/GetLoanDisbursement/AppprovalSuceessPopUp")
);
const RejectedPopUp = React.lazy(() =>
  import("../Finance/LAC/GetLoanDisbursement/RejectedPopUp")
);

const StickyFooter = ({
  isActive,
  showBackToTopWidget = true,
  workEvenIfActive = false,
}) => {
  const userDetails = useUserDetails();

  const userIP = useUserIP(); // set USER IP in local storage

  const arya_shakti_number = useSelector(
    (globalState) => globalState?.aryaShaktiMobileReducer?.arya_shakti_number
  );
  String.prototype.isNumber = function () {
    return /^\d+$/.test(this);
  };

  let userName = userDetails?.first_name;
  if (userName) {
    userName = userName?.trim()?.isNumber() ? "" : userName;
  }
  if (userName) {
    userName = userName
      ?.split(" ")
      ?.map((n) => n[0])
      ?.join("");
  }

  const isBanker = useIsBanker();

  const footerArray = [
    {
      title: "Home",
      onClick: "/",
      sameUrl: window.location.pathname == "/",
    },
    {
      title: "Storage",
      onClick: "/my-storage/commodities",
      sameUrl: window.location.pathname == "/my-storage/commodities",
    },
    {
      title: "Trade",
      onClick: isBanker
        ? "/blockchain/banker/verify-token"
        : "/trade/my-demands",
      sameUrl:
        window.location.pathname ==
        (isBanker ? "/blockchain/banker/verify-token" : "/trade/my-demands"),
    },
    {
      title: "Finance",
      onClick: "/finance",
      sameUrl: window.location.pathname == "/finance",
    },
  ];

  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showDisbursed, setShowDisbursed] = useState(false);

  const [loginShow, setLoginShow] = useState(false);
  const [regShow, setRegShow] = useState(false);

  const [rejectShow, setRejectShow] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const [userLoggedIn] = useIsUserLoggedIn();
  const dispatch = useDispatch();
  const userLanguage = useUserLanguage();
  const [disbursedData, setDisbursedData] = useState("");
  const location = useLocation();

  const paramsLanguage = getQueryParam("lang");
  const { i18n } = useTranslation();
  let goToTopRef = useRef("");

  const languageFromReducer = useSelector(getLanguageFromStore) || {};

  const logout = async () => {
    let lang = localStorage.getItem("lang");
    var response = await LoginServices.userLogout();

    if (response && response !== null) {
      dispatch(setUserInfo({ userLoggedIn: false }));
      localStorage.clear();
      localStorage.setItem("lang", lang);
      localStorage.setItem("logout", true);
      if (userLanguage) {
        i18next.changeLanguage(userLanguage["lang_id"]);
      }
    }
  };

  const getDataAryaShakti = async () => {
    let dataobj = {
      t: getQueryParam("t"),
      mobile: getQueryParam("mobile"),
      m: getQueryParam("m"),
    };
    const res = await LoginServices.aryaShaktiMobile(dataobj);

    if (res?.data?.data?.mobile) {
      dispatch(
        setAryaShaktiMobileInfo({
          number: res.data.data.mobile,
        })
      );
    }
  };

  useEffect(() => {
    window.prevScrollpos = window.pageYOffset;
    if (
      (!arya_shakti_number && getQueryParam("mobile")) ||
      (arya_shakti_number &&
        getQueryParam("mobile") &&
        arya_shakti_number != getQueryParam("mobile"))
    ) {
      getDataAryaShakti();
    }
    window.addEventListener("scroll", toggleVisibility);

    let sessionLogout = localStorage.getItem("sessionLogout");
    if (sessionLogout) {
      var a = moment(sessionLogout);
      var b = moment(new Date());
      var c = Math.abs(a.diff(b, "minutes"));
      var minutes = c;
      if (userLoggedIn) {
        if (minutes > 29) {
          logout();
        }
      }
    }
    setTimeout(() => {
      let disbruse = localStorage.getItem("LacPopup");
      if (disbruse === "approved") {
        apiDisburseCall();
      } else if (disbruse === "expired") {
        setRejectShow(true);
        localStorage.setItem("LacPopup", false);
      }
    }, 3000);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
      delete window.prevScrollpos;
    };
  }, []);

  const apiDisburseCall = async () => {
    const requestList = await LoanApprovalAPI();
    if (
      requestList &&
      requestList?.data?.data &&
      !Array.isArray(requestList?.data?.data)
    ) {
      setDisbursedData(requestList?.data?.data);
      setShowDisbursed(true);
      localStorage.setItem("LacPopup", false);
    }
  };

  const toggleVisibility = () => {
    var currentScrollPos = window.pageYOffset;
    if (window.prevScrollpos > currentScrollPos) {
      // if (window.pageYOffset > window.innerHeight) {
      show != true && setShow(true);
      if (goToTopRef) {
        clearTimeout(goToTopRef.current);
        goToTopRef.current = setTimeout(() => {
          setShow(false);
        }, 3000);
      } else {
        clearTimeout(goToTopRef.current);
        goToTopRef.current = "";
      }
      // } else {
      //   setShow(false);
      // }
    } else if (window.prevScrollpos != currentScrollPos) {
      setShow(false);
    }
    window.prevScrollpos = currentScrollPos;
  };

  const selectedLanguage = languageOptions.find(
    (el) => el.lang_id == paramsLanguage
  );

  useEffect(() => {
    if (window.location.origin === "https://www.arya.ag") {
      eventTracker("event", "page_view", {
        send_to: "G-JWZVJZLDN9", // PROD GA4
        page_location: window.location.href,
        page_path: location.pathname === "/" ? "homepage" : location.pathname,
        lang:
          languageFromReducer?.lang_id === "en" || !languageFromReducer?.lang_id
            ? "English"
            : titleCase(languageFromReducer?.lang_id),
      });
    } else {
      eventTracker("event", "page_view", {
        send_to: "G-XB7HL3B5Z4", //UAT GA4
        page_location: window.location.href,
        page_path: location.pathname === "/" ? "homepage" : location.pathname,

        lang:
          languageFromReducer?.lang_id === "en" || !languageFromReducer?.lang_id
            ? "English"
            : titleCase(languageFromReducer?.lang_id),
      });
    }

    if (paramsLanguage) {
      localStorage.setItem("lang", selectedLanguage?.lang_id);
      i18n.changeLanguage(selectedLanguage?.lang_id);
      dispatch(
        setUserLanguage({
          ...selectedLanguage,
        })
      );
    }
  }, [location?.pathname]);

  const profileClicked = () => {
    eventTracker(
      "event",
      userLoggedIn ? FOOTER_NAV_PROFILE : FOOTER_NAV_LOGIN,
      { pagename: location.pathname === "/" ? "homepage" : location.pathname }
    );
    if (userLoggedIn) {
      history.push("/my-profile");
    } else {
      setLoginShow(true);
    }
  };

  return (
    <>
      {/* <div className={style["sticky-footer-height"]}></div> */}
      {loginShow && (
        <Suspense fallback={<></>}>
          <Login
            show={loginShow}
            setShow={setLoginShow}
            setRegShow={setRegShow}
          />
        </Suspense>
      )}
      {regShow && (
        <Suspense fallback={<></>}>
          <Registration show={regShow} setShow={setRegShow} />
        </Suspense>
      )}
      {disbursedData && show ? (
        <ApprovalSuccessPopUp
          data={disbursedData}
          show={showDisbursed}
          setShow={setShowDisbursed}
        />
      ) : (
        ""
      )}
      {rejectShow && (
        <RejectedPopUp show={rejectShow} setShow={setRejectShow} />
      )}

      {/* {location.pathname === "/" && <GetInTouchPopupForm />} */}
      <GetInTouchPopupForm />

      <Card
        className={` text-center width-100 padding8 d-flex ${style["stickyFooter"]} ${style["justify-content-around"]} backgroundWhite`}
      >
        {showBackToTopWidget && show && (
          <BackToTop show={show} setShow={setShow} />
        )}
        {footerArray.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                if (item.title) {
                  eventTracker("event", footerTitleEventObject[item.title], {
                    pagename:
                      location.pathname === "/"
                        ? "homepage"
                        : location.pathname,
                  });
                }

                if (item.sameUrl) {
                  BackToTopFunc();
                } else {
                  history.push(item.onClick);
                }
              }}
            >
              <IconsSvg
                iconName={item.title}
                isActive={item.title == isActive}
              />
              <HeadLines
                className={`padding-top4  font-size12 line-height14 letterSpacing025 ${
                  item.title == isActive
                    ? "colorAccent4 font-weight500"
                    : "colorDark8"
                }`}
                text={item.title}
              />
            </div>
          );
        })}

        <div key={"profile"} onClick={() => profileClicked()}>
          {userName ? (
            <div className="d-flex justify-content-center">
              <div
                className={`font-weight400 line-height12 justify-content-center font-size10 d-flex align-items-center ${style["IconHeightWidth"]}  ${style["border-radius50"]} colorAccent5 ${style["profileBack"]}`}
              >
                {userName[0]?.toUpperCase()}
                {userName[1]?.toUpperCase()}
              </div>
            </div>
          ) : (
            <>
              <IconsSvg iconName={"Profile"} isActive={isActive == "Profile"} />
            </>
          )}
          <HeadLines
            className={`padding-top4  font-size12 line-height14 letterSpacing025 ${
              isActive == "Profile"
                ? "colorAccent4 font-weight500"
                : "colorDark8"
            }`}
            text={userLoggedIn ? "Profile" : "Login"}
          />
        </div>
      </Card>
    </>
  );
};
export default StickyFooter;
