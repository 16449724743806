import React from "react";
import HeadLines from "../../../../CommonComponents/HeadLines";
import { isMobile } from "../../../../helperFunc";
import style from "./flextTitle.module.css";
function FlexTitleComponent({ title, count, rightComponent, customClass }) {
  return (
    <div className={isMobile && "padding16"}>
      <div
        className={`d-flex align-items-center justify-content-space-between ${customClass}`}
      >
        <div className="d-flex">
          <HeadLines
            text={title}
            className={`${
              isMobile
                ? "font-size16 line-height19 letterSpacing015 "
                : "font-size18 line-height21 letterSpacing005 "
            } colorDark1 font-weight500`}
          />
          {count && (
            <HeadLines
              text={count}
              className={`${style["countBox"]} text-color-white margin-left12`}
            />
          )}
        </div>
        <div>{rightComponent}</div>
      </div>
    </div>
  );
}

export default FlexTitleComponent;
