import Farmer from "./../Images/Registration/Farmer.png";
import FPO from "./../Images/Registration/FPO.png";
import Banks from "./../Images/Registration/Banks.png";
import Miller from "./../Images/Registration/Miller.png";
import Broker from "./../Images/Registration/Broker.png";
import Corporate from "./../Images/Registration/Corporate.png";
import Trader from "./../Images/Registration/Trader.png";
import Gst from "./../Images/Registration/Gst.png";
import Pan from "./../Images/Registration/Pan.png";
import Aadhar from "./../Images/Registration/Aadhar.png";

export const STEPS = {
  FIRST_STEP: 1,
  SECOND_STEP: 2,
  THIRD_STEP: 3,
  FOURTH_STEP: 4,
};
export const BankSTEPS = {
  FIRST_STEP: 1,
  SECOND_STEP: 2,
  THIRD_STEP: 3,
};

export const textArray = [
  "Select role to get customized experience on arya.ag",
  "To get personalized results, please select preferred commodity and location",
  "Verified users receives 10x enquiries",
  "Receive all the latest updates via email",
];

export const desktopTextArray = [
  "Welcome to arya.ag, select role to get customized experience on arya.ag",
  "To get personalized results, please select preferred commodity and location",
  "Verified users receives 10x enquiries",
  "Receive all the latest updates via email",
];

export const skipArray = [
  [
    "Are you sure you want to skip role selection?",
    "Selecting user type allows us to create more personalized experience for you",
    "Skip Role Selection",
  ],
  [
    "Are you sure you want to skip selecting preferences?",
    "Selecting preference allows us to create more personalized experience for you",
    "Skip Preference Selection",
  ],
  [
    "Are you sure you want to skip KYC Verification?",
    "Completing KYC Verification allows us to create more personalized experience for you",
    "Skip KYC Registration",
  ],
];
export const Info = {
  farmer: ["Quicker financing", "10x more enquiries", "Faster trade closure"],
  banker: [
    "Access to arya.ag’s unfunded inventory",
    "Make risk free loan offers using Blockchain",
    "Make informed decisions by accessing warehouse live feeds",
  ],
  others: ["Quicker financing", "10x more enquiries", "Faster trade closure"],
};

export const PROGRESS_TAB_STEPS = [
  {
    stepNumber: STEPS.FIRST_STEP,
    stepText: "Select Role",
  },
  {
    stepNumber: STEPS.SECOND_STEP,
    stepText: "Select Preferences",
  },
  {
    stepNumber: STEPS.THIRD_STEP,
    stepText: "KYC Verification",
  },
  {
    stepNumber: STEPS.FOURTH_STEP,
    stepText: "Add Details",
  },
];
export const BANK_PROGRESS_TAB_STEPS = [
  {
    stepNumber: STEPS.FIRST_STEP,
    stepText: "Select Role",
  },
  {
    stepNumber: STEPS.SECOND_STEP,
    stepText: "KYC Verification",
  },
  {
    stepNumber: STEPS.THIRD_STEP,
    stepText: "Add Details",
  },
];
export const CardsData = [
  {
    label: "Farmer",
    image: Farmer,
    background: "Farmer_Background",
    height: "Farmer_Height",
  },
  {
    label: "Trader",
    image: Trader,
    background: "Trader_Background",
    height: "Trader_Height",
  },
  {
    label: "FPO",
    image: FPO,
    background: "FPO_Background",
    height: "FPO_Height",
  },
  {
    label: "Banks & NBFC’s",
    image: Banks,
    background: "Banks_Background",
    height: "Banks_Height",
  },
  {
    label: "Broker",
    image: Broker,
    background: "Broker_Background",
    height: "Broker_Height",
  },
  {
    label: "Miller & Processor",
    image: Miller,
    background: "Miller_Background",
    height: "Miller_Height",
  },
  {
    label: "Corporate",
    image: Corporate,
    background: "Corporate_Background",
    height: "Corporate_Height",
  },
];
export const KycFormData = [
  {
    label: "Aadhar",
    image: Aadhar,
  },
  {
    label: "Pan",
    image: Pan,
  },
  {
    label: "Gst",
    image: Gst,
  },
];
const array = (a, b, c, d = false) => {
  let aadharDoc = {};
  if (d) {
    return [
      {
        field_name: a,
        label: b,
        type: c,
        min: 12,
        max: 12,
        isRequired: true,
      },
      {
        field_name: "aadharDoc",
        label: "Aadhar Image",
        type: "string",
        isRequired: true,
      },
    ];
  } else
    return [
      {
        field_name: a,
        label: b,
        type: c,
        isRequired: true,
        // min: 10,
        // max: 10,
      },
      { ...aadharDoc },
    ];
};
export const docRules = (docChosen) => {
  if (docChosen == "Pan") return array("Pan", "PAN", "pan");
  else if (docChosen == "Aadhar")
    return array("Aadhar", "Aadhar", "number", true);
  else if (docChosen == "Gst") return array("Gst", "GST", "gstin");
};

export const addDetails = () => {

  return [
    {
      field_name: "pincode",
      label: "Pincode",
      type: "number",
      min: 6,
      max: 6,
      isRequired: true,
    },
    {
      field_name: "state",
      label: "State",
      type: "string",
      isRequired: true,
    },
    {
      field_name: "city",
      label: "District",
      type: "string",
      isRequired: true,
    },
    {
      field_name: "address",
      label: "Address",
      type: "string",
      isRequired: true,
    },
    {
      field_name: "email",
      label: "E-mail",
      type: "email",
      isRequired: false,
    },
    {
      field_name: "Tan",
      label: "Tan",
      type: "string",
      isRequired: false,
    }
  ];
};

export const bankRules = () => {
  return [
    {
      field_name: "roi_from",
      label: "ROI from",
      type: "number",
      isRequired: true,
    },
    {
      field_name: "roi_to",
      label: "ROI to",
      type: "number",
      isRequired: true,
    },

    {
      field_name: "logo",
      label: "Bank Logo",
      type: "string",
      isRequired: true,
    },
  ];
};

export const tatArray = [
  { label: "less than 1 day", value: 0 },
  { label: "1-3 days", value: 1 },
  { label: "3-7 days", value: 2 },
  { label: "more than 7 days", value: 3 },
];

export const docLength = {
  Aadhar: 12,
  Pan: 10,
  Gst: 15,
};

export const RESULT = {
  status: "success",
  status_code: 200,
  message: "Your KYC Done Successfully",
  data: {
    user_id: 61377,
    user_type: "Trader",
    user_types: [
      "FARMERS",
      "TRADERS",
      "FPO",
      "PRIVATE LTD",
      "MULTIPLE OWNER",
      "PROPRITER",
      "PATNERSHIP",
      "MNCs",
      "Banks",
      "Govt Agencies",
      "Farmer Organisations",
    ],
    member: {
      id: 61377,
      arya_id: null,
      phone_number: "1111111222",
      address_id: 21361,
      first_name: "NEERAJ KUMAR AWADHIYA ",
      last_name: "",
      email: null,
      parent_id: null,
      email_cc1: null,
      email_cc2: null,
      email_cc3: null,
      email_cc4: null,
      username: null,
      profile_multimedia_id: null,
      cover_multimedia_id: null,
      phone_number2: null,
      dob: null,
      gender: null,
      gstin_number: null,
      pan_number: "AZLPA0763G",
      aadhar_number: null,
      voter_id: null,
      number_of_warehouse: null,
      year_of_establishment: null,
      entity_name: "NEERAJ KUMAR AWADHIYA",
      entity_type: null,
      bookwithoutpayment: 1,
      bank_name: null,
      branch_name: null,
      branch_code: null,
      bank_account: null,
      ifsc_code: null,
      comparison_parameters: null,
      email_verified: 0,
      sms_verified: 0,
      service: null,
      status: 1,
      gst_billing_state: null,
      communication_address: null,
      area_manager: null,
      kyc: "1",
      buy_sell_limit: null,
      last_active: "2023-03-27 00:00:00",
      documentType: null,
      gst_file: null,
      pan_file: null,
      adhar_no_voter_no_file_one: null,
      adhar_no_voter_no_file_two: null,
      preferred_user_types: null,
      user_type: "Trader",
      pan_image_id: null,
      aadhar_front_image_id: null,
      aadhar_back_image_id: null,
      created_by: null,
      updated_by: null,
      created_at: "2023-03-27 05:45:27",
      updated_at: "2023-03-27 05:47:29",
      created_name: null,
      created_mobile: null,
      resgister_type: 1,
      rm_name: null,
      rm_id: null,
      child_verify_doc: null,
      last_sessid:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS11YXQuYXJ5YS5hZy9hcGkvdjIvdmVyaWZ5LWJ5LW1vYmlsZSIsImlhdCI6MTY3OTg5NTkyNywiZXhwIjoxNjgxMTA1NTI3LCJuYmYiOjE2Nzk4OTU5MjcsImp0aSI6IlpnaDdZakFVOFgxa1p3czIiLCJzdWIiOjYxMzc3LCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.UUsWNG6XoN4PrmrG49iaySJC5gmjXlJY7ifNmkS4H4g",
      sh_user_id: null,
      sh_seller_alias: null,
      sh_seller_title: null,
      cookie: null,
      ltp: 0,
      stp: 0,
      buyer: 0,
      seller: 0,
      whatsapp_optin: 2,
      tat: "0",
      roi_from: null,
      roi_to: null,
      approved: 0,
      lang: null,
      tan: null,
      tan_verified: "0",
      pan_verified: 0,
      gst_verified: 0,
      is_catalog_hide_details: 0,
      rollover: null,
      isWHPremium: null,
      tdsRateWH: null,
      isADPremium: null,
      tdsRateAD: null,
      bank_required_docs: null,
      is_catalog_seller_without_kyc: 0,
      from_trade: 0,
      roles: [
        {
          id: 3,
          name: "CORPORATE",
          guard_name: "api",
          user_group: 0,
          created_at: "2020-01-03 05:02:33",
          updated_at: "2020-01-03 05:02:33",
          pivot: {
            model_id: 61377,
            role_id: 3,
            model_type: "App\\User",
          },
        },
      ],
    },
    token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS11YXQuYXJ5YS5hZy9hcGkvdjIvcmVnaXN0ZXItdXBkYXRlLWJ5LW1vYmlsZSIsImlhdCI6MTY3OTg5NjA0OSwiZXhwIjoxNjgxMTA1NjQ5LCJuYmYiOjE2Nzk4OTYwNDksImp0aSI6Imh0aTBQQ2hnUlN2N0lHbmgiLCJzdWIiOjYxMzc3LCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.xUki4h_EfiMqUKl5T9KlwNfAN-2gYkUmf_ImlPL2MQM",
    permissions: [
      "add-warehouse",
      "warehouse-details",
      "dashboard-warehouse",
      "add-company-location",
      "update-company-location",
      "warehouse",
      "add-favorite",
      "favorite-list",
      "recently-viewed",
      "add-to-cart",
      "remove-from-cart",
      "view-cart",
      "warehouse-request-form",
      "add-company-location-access",
      "user-details",
      "update-user",
      "compare-warehouse",
      "service-request",
      "all-service-request",
      "comparison-parameters",
      "company-location-detail",
      "company-location-list",
      "company-user",
      "company-user-detail",
      "add-company-user",
      "update-company-user-status",
      "my-booking",
      "cart-amount",
      "check-free-payment",
      "checkout",
      "move-to-wishlist",
      "move-to-cart",
      "view-wishlist",
      "remove-from-wishlist",
      "booking-status",
      "similar-warehouse",
      "booked-warehouse",
      "create-order",
      "update-auth-user",
      "allow-booking",
      "service-request-form",
    ],
    corporate: null,
    cart: 0,
    userpermission: [],
    userPlan: null,
    cartNegotiation: 0,
    IS_LAC_ALLOWED: 0,
    success_status: "1",
  },
};
