import React, { useEffect, useState } from "react";
import InputFieldWithTitle from "../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import { errorInfoIcon } from "../icon_assets";
import style from "./style.module.css";
import textAreaStyles from "../CommonComponents/TextAreaWithTitle/styles.module.css";
import TextAreaWithTitle from "../CommonComponents/TextAreaWithTitle/TextAreaWithTitle";
import SkipSubmitButton from "./SkipSubmitButton";
import { CommonApiService } from "../../../../_services";
// import { Validation } from "../../../../_helpers/validation/Validation";
import { addDetails, bankRules, tatArray } from "./Constant";
import { registerUpdateByMobile } from "../../../../_services/Arya2.0/Registration";
import useUserDetails from "../CustomHooks/useUserDetails";
import { setUserInfo } from "../Redux/UserInfo/actions";
import { useDispatch } from "react-redux";
import toastr from "toastr";
import {
  getPanFromGst,
  // handleChange,
  isNumberOnly,
  maskNumber,
  signedUrlFromDoms,
  validationFunction,
} from "../CommonFunctions/Function";
import UploadDoc from "./UploadDoc";
import HeadLines from "../CommonComponents/HeadLines";
import CustomReactSelect from "../CommonComponents/CustomReactSelect/CustomReactSelect";
import { customToastr } from "../CustomToastr";
import { useTranslation } from "react-i18next";
import UploadDocumentComponent from "../Finance/Components/UploadFile/UploadDocumentComponent";
import { encryptData, isMobile } from "../helperFunc";
import HeadLinesSpan from "../CommonComponents/HeadlinesSpan";
import CustomRadioBtn from "../CommonComponents/CustomRadioBtn/CustomRadioBtn";
import EmailLinkSentPopUp from "./EmailLinkSentPopUp";
import { LoginServices } from "../../../../_services/Arya2.0/Login/LoginServices";
import Loader from "../CommonComponents/Loader/Loader";
import {
  generateSignedUrl,
  saveDocThroughDoms,
} from "../../../../_services/Arya2.0/DomsApi";
import CustomCheckBox from "../CommonComponents/CustomCheckBox/CustomCheckBox";
import ErrorMessage from "../CommonComponents/ErrorMessage/ErrorMessage";
import eyeIcon from "./../DesktopImages/Home/eye.svg";
import darkeye from "./../DesktopImages/Home/darkeye.svg";
import eyeCutIcon from "./../DesktopImages/Home/eyeCutIcon.svg";
const initiate = {
  email: "",
  pincode: "",
  city: "",
  state: "",
  address: "",
  tat: { label: "less than 1 day", value: 0 },
  logo: "",
  roi_to: "",
  roi_from: "",
  msme_certificate: null,
};
const MSME_TYPE = {
  YES: "1",
  NO: "2",
};
const AddDetails = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(initiate);
  const [error, setError] = useState("");
  const [stepNo, setStepNo] = useState(1);
  const { t: translation } = useTranslation();
  const [radioCheckMsme, setRadioCheckMsme] = useState("");
  const [imageName, setImageName] = useState({});
  const [verifyLoader, setVerifyLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tanVerified, setTanVerified] = useState(false);
  const [disable, setDisable] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [msmeError, setMSMEError] = useState("");
  const [showEmailSentPopUp, setShowEmailSentPopUp] = useState(false);
  const [showTanNumber, setShowTanNumber] = useState(false);
  const userDetails = useUserDetails();
  const [errorMessage, setErrorMessage] = useState("");
  const [showKycText, setShowKycText] = useState(false);

  const { name = "" } = props;
  const continueClicked = async () => {
    setMSMEError("");
    let flag = validationFunction(data, addDetails, setError);
    if (props?.dataObj?.docChosen === "Gst") {
      if (props?.dataObj?.Pan === "") {
        setError((prev) => ({
          ...prev,
          ["Pan"]: "Pan is Required",
        }));
      } else if (props?.dataObj?.Pan?.length !== 10) {
        setError((prev) => ({
          ...prev,
          ["Pan"]: "Please enter valid pan number",
        }));
        flag = 1;
      }
    }
    if (data.msme_certificate === null && radioCheckMsme === MSME_TYPE.YES) {
      setMSMEError("MSME Certificate is Required");
      flag = 1;
    }
    if (flag == 0) {
      props.dataObj.userType != "Banks & NBFC’s" ? apiHit() : setStepNo(2);
    }
  };
  const submitClick = async () => {
    let flag = validationFunction(data, bankRules, setError);
    if (
      data.roi_from &&
      data.roi_to &&
      parseFloat(data.roi_from) > parseFloat(data.roi_to)
    ) {
      setError((prev) => ({
        ...prev,
        roi_from: "ROI from can not be greater than ROI to",
      }));
      flag = 1;
    }
    if (flag == 0) {
      apiHit();
    }
  };
  const apiHit = async () => {
    setIsLoading(true);
    let location = props.dataObj["preferred_buyer_locations"].map((item) => {
      return item.label;
    });
    let commodity = props.dataObj["preferred_buyer_commodities"].map((item) => {
      return item.label;
    });

    const formData = new FormData();
    // formData.append(
    //   "image",
    //   props.dataObj["aadharDoc"],
    //   props.dataObj["aadharDoc"]["name"]
    // );

    let newDataObj = {
      name: props.dataObj.name,
      email: data.email,
      mobile: userDetails.phone_number,
      pan_number: props.dataObj["Pan"] ? encryptData(props.dataObj["Pan"]) : "",
      tan: tanVerified ? props?.dataObj?.["Tan"] : "",
      tan_verified: tanVerified ? 1 : 0,
      aadhar_number: props.dataObj["Aadhar"]
        ? encryptData(props.dataObj["Aadhar"])
        : "",
      gstin_number: props.dataObj["Gst"]
        ? encryptData(props.dataObj["Gst"])
        : "",
      user_type: props.dataObj.userType,
      address: data.address,
      state: data.state_id,
      district: data.city_id,
      pincode: data.pincode,
      pan_image: props.dataObj["panDoc"],
      aadhar_front_image: props.dataObj["aadharDoc"],
      aadhar_back_image: null,
      preferred_buyer_locations: JSON.stringify(location),
      preferred_buyer_commodities: JSON.stringify(commodity),
      roi_from: data.roi_from,
      roi_to: data.roi_to,
      logo: data.logo,
      tat: data.tat.value,
      msme_certificate: data?.msme_certificate || null,
    };

    let flag = 1;
    if (radioCheckMsme && data?.msme_certificate) {
      const setErrorCallBack = (errorText = "") => {
        setMSMEError(errorText);
        setIsLoading(false);
      };
      const signedUrl = await signedUrlFromDoms(
        data?.msme_certificate,
        setErrorCallBack
      );
      if (signedUrl) {
        newDataObj.msme_certificate_url = signedUrl;
      } else {
        flag = 0;
      }
    }

    if (props.dataObj["aadharDoc"]) {
      const setErrorCallBack2 = (errorText = "") => {
        setErrorMessage(errorText);
        setIsLoading(false);
      };
      const signedUrl = await signedUrlFromDoms(
        props.dataObj["aadharDoc"],
        setErrorCallBack2
      );
      if (signedUrl) {
        newDataObj.aadhar_front_image_signed_url = signedUrl;
      } else {
        flag = 0;
      }
    }

    (function () {
      for (const key in newDataObj) {
        formData.append(key, newDataObj[key]);
      }
    })();
    if (flag === 1) {
      const result = await registerUpdateByMobile(formData);
      if (
        result?.data?.success_status === "0" ||
        (result?.status_code > 400 && result?.status_code <= 418) ||
        result?.status_code >= 420
      ) {
        setErrorMessage(
          result?.message
            ? Array.isArray(result?.message)
              ? result?.message[0]
              : result.message
            : result?.msg
            ? result?.msg
            : ""
        );
      } else if (
        result !== null &&
        result !== undefined &&
        result &&
        typeof result.data === "object" &&
        result.data.success_status !== "0"
      ) {
        if (result.data.success_status === "1" && result.data.token) {
          localStorage.setItem("authToken", result.data.token);
          localStorage.setItem(
            "userDashBoardData",
            JSON.stringify(result.data)
          );
          localStorage.setItem("notifications", JSON.stringify(result.data));
          localStorage.setItem(
            "userDashBoardDataplan",
            JSON.stringify(result.data.userPlan)
          );

          localStorage.setItem(
            "disbrusementPopup",
            result.data.disbursementPopUp
          );
          if (
            result?.data?.data?.role_name &&
            result.data?.member?.roles[0].name === result.data.role_name
          ) {
            localStorage.setItem("isOtherUser", "isOtherUser");
          }
          localStorage.setItem("aryauserType", result.data.member.roles[0].id);
          localStorage.setItem("isKyc", result.data.member.kyc);
          localStorage.setItem("isParent", result.data.member.parent_id);
          localStorage.setItem("user_type", result.data.user_type);

          localStorage.setItem("rms_expire", result.data.rms_expire);
          localStorage.setItem("rms_token", result.data.rms_token);
          localStorage.setItem("rms_user", result.data.rms_user);
          dispatch(
            setUserInfo({
              ...(result.data?.member || {}),
              permissions: result.data?.permissions,
              userData: result.data,
              userLoggedIn: true,
            })
          );

          customToastr({
            text: "Registration Successfull",
            // type: "success",
            // positionClass: tcs["toast-top-center2"],
            // toastClass: tcs["email-otp-toastr"],
          });
          // const resultResponse = await LoginServices.sendEmailVerifyLink({
          //   email: data.email,
          // });
          // if (resultResponse?.data?.status_code === 200) {

          // } else {
          //   props.setShow(false);

          // }
          setShowEmailSentPopUp(true);
          setIsLoading(false);
          setErrorMessage("");
        }
      }
    }
    setIsLoading(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (
      name != "pincode" ||
      (name == "pincode" &&
        value.length <= 6 &&
        (!value || isNumberOnly(value)))
    ) {
      if (
        name != "address" ||
        (name == "address" && (!value || value.trim()))
      ) {
        setData((prev) => ({ ...prev, [name]: value }));
      }
    }
    if (name == "pincode" && value.length == 6) {
      Pincode(value);
    }
    if (props?.dataObj?.docChosen === "Gst" && name === "Pan") {
      props?.setDataObj((prev) => ({
        ...prev,
        ["Pan"]: value,
      }));
    }
    if (name === "Tan") {
      setTanVerified(false);
      setDisable(true);
      // setErrorMsg("");
      props?.setDataObj((prev) => ({
        ...prev,
        ["Tan"]: value.replace(/[^A-Z0-9]/gi, "").toUpperCase(),
      }));
    }
  };

  const onChangeROI = (e) => {
    const { name, value } = e.target;
    if (
      value == "" ||
      (name == "roi_from" && value <= data["roi_to"]) ||
      (name == "roi_to" && value >= data["roi_from"])
    ) {
      setData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const Pincode = (value) => {
    CommonApiService.getAddressByPincode(value).then((res) => {
      if (res?.data?.data) {
        const result = res.data.data;
        setData((prev) => ({
          ...prev,
          ["state"]: result.state_name,
          ["city"]: result.city_name,
          ["city_id"]: result.city_id,
          ["state_id"]: result.state_id,
        }));
      } else {
        setData((prev) => ({
          ...prev,
          ["state"]: "",
          ["city"]: "",
          ["city_id"]: "",
          ["state_id"]: "",
        }));
        // setPincodeError("Invalid Pincode");
      }
    });
  };

  // const callBackFunction = (image) => {
  //   setData((prev) => ({ ...prev, ["logo"]: image }));
  // };

  const handleImageChange = (event) => {
    // window.aaa = event.target.files;
    const a = event.target.files;
    if (event.target.name === "msme") {
      setData((prev) => ({
        ...prev,
        ["msme_certificate"]: a?.[0] ? a?.[0] : null,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        ["logo"]: a?.[0] || {},
      }));
    }
  };
  useEffect(() => {
    if (props?.dataObj?.docChosen === "Gst") {
      props?.setDataObj((prev) => ({
        ...prev,
        ["Pan"]: getPanFromGst(props.dataObj[props.dataObj.docChosen]),
      }));
    }
  }, [props?.dataObj?.docChosen]);
  const handleVerifyTan = async () => {
    setVerifyLoader(true);
    const user_id = userDetails.id;
    // const user_id = JSON.stringify(user).member.id;
    try {
      const data = {
        company_name: props?.dataObj.name,
        tan: props?.dataObj.Tan,
        id: user_id,
      };
      const result = await CommonApiService.verifyTan(data);
      if (result?.data?.status_code === 200) {
        result?.data?.data?.result?.verified === true
          ? setTanVerified(true)
          : setError((prev) => ({
              ...prev,
              ["Tan"]: "Invalid TAN Number",
            }));
      } else {
        // customToastr({
        //   type: "warning",
        //   text: "Invalid TAN Number",
        //   timeOut: 3000,
        // });
        // setError("Invalid TAN Number");
        setError((prev) => ({
          ...prev,
          ["Tan"]: "Invalid TAN Number",
        }));
      }
    } catch (ex) {
      setDisable(true);
    } finally {
      setVerifyLoader(false);
    }
  };
  useEffect(() => {
    props.ScrollToTop();
  }, []);
  const fishEyeIcon = (
    <>
      <img
        src={
          props?.dataObj?.["Pan"]
            ? showKycText
              ? eyeCutIcon
              : darkeye
            : eyeIcon
        }
        onClick={() => setShowKycText(!showKycText)}
        className={`${style["positionEyeCSS"]} positionAbsolute cursorPointer`}
        height={isMobile ? "18" : "20"}
        width={isMobile ? "18" : "20"}
        alt=""
      />
    </>
  );
  return (
    <>
      <>{isLoading && <Loader isLoading={isLoading} />}</>
      {showEmailSentPopUp && (
        <EmailLinkSentPopUp
          setShowEmailSentPopUp={setShowEmailSentPopUp}
          showEmailSentPopUp={showEmailSentPopUp}
          popUpShow={props.setShow}
          email={data?.email}
          setBlurBackGround={props.setBlurBackGround}
        />
      )}
      <div className="margin-left16 margin-right16">
        <HeadLines
          text="Please enter the required details"
          className={`     padding-right4 ${
            isMobile
              ? " padding-left4 margin-top16 margin-bottom16 font-weight400 font-size12 line-height14 letterSpacing025"
              : `margin-top24 font-weight500 colorDark5 font-size20 line-height23 letterSpacing001 margin-bottom24`
          }`}
        />
        {stepNo == 1 || props.dataObj.userType != "Banks & NBFC’s" ? (
          <>
            <div className={isMobile ? "" : "d-flex"}>
              <InputFieldWithTitle
                titleClassName={`   ${
                  isMobile
                    ? `font-weight500 font-size14 line-height16 ${style["color333333"]}`
                    : "font-size16 line-height19 font-weight600 colorDark9"
                } letterSpacing05`}
                title={
                  "Name (as per " +
                  (props?.dataObj?.docChosen != "Aadhar"
                    ? props?.dataObj?.docChosen?.toUpperCase()
                    : props.dataObj.docChosen) +
                  ")"
                }
                isCompulsory={true}
                disabled={true}
                compulsoryStarClassName="line-height14"
                inputClassName={`margin-top8   width-100 boxRadius4 padding12  letterSpacing001 ${
                  style["inputBorder"]
                } font-weight400  ${
                  isMobile
                    ? "font-size14 line-height16"
                    : "font-size16 line-height19"
                }`}
                disableInputClassName={`input-style-disable`}
                errorInputClassName={"input-style-error"}
                errorClassName={"input-error-style"}
                showError={""}
                errorIcon={errorInfoIcon}
                name="name"
                value={props.dataObj.name}
                onChange={onChange}
                className={isMobile ? "" : "width-100"}
                marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
              />
              <InputFieldWithTitle
                titleClassName={` ${
                  isMobile
                    ? `font-weight500 font-size14 line-height16 ${style["color333333"]}`
                    : "font-size16 line-height19 font-weight600 colorDark9"
                } letterSpacing05`}
                title={
                  "Your " +
                  (props?.dataObj?.docChosen != "Aadhar"
                    ? props?.dataObj?.docChosen?.toUpperCase()
                    : props.dataObj.docChosen) +
                  " Number"
                }
                disabled={true}
                isCompulsory={true}
                compulsoryStarClassName="line-height14"
                inputClassName={`margin-top8   width-100 boxRadius4 padding12 ${
                  style["inputBorder"]
                } font-weight400 ${
                  isMobile
                    ? "font-size14 line-height16"
                    : "font-size16 line-height19"
                } letterSpacing001`}
                disableInputClassName={`input-style-disable`}
                errorInputClassName={"input-style-error"}
                errorClassName={"input-error-style"}
                showError={""}
                errorIcon={errorInfoIcon}
                name={props.kycDoc}
                value={maskNumber(props.dataObj[props.dataObj.docChosen])}
                onChange={onChange}
                className={isMobile ? "" : "width-100 margin-left20"}
                marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
              />
            </div>
            {props?.dataObj?.docChosen === "Gst" && (
              <>
                <InputFieldWithTitle
                  titleClassName={` ${
                    isMobile
                      ? `font-weight500 font-size14 line-height16 ${style["color333333"]}`
                      : "font-size16 line-height19 font-weight600 colorDark9"
                  } letterSpacing05`}
                  title={"Enter Pan Number"}
                  disabled={false}
                  isCompulsory={false}
                  compulsoryStarClassName="line-height14"
                  inputClassName={`margin-top8   width-100 boxRadius4 padding12 ${
                    style["inputBorder"]
                  } font-weight400 ${
                    isMobile
                      ? "font-size14 line-height16"
                      : "font-size16 line-height19"
                  } letterSpacing001 ${
                    showKycText ? "" : style["pox-password"]
                  }`}
                  disableInputClassName={`input-style-disable`}
                  errorInputClassName={"input-style-error"}
                  errorClassName={"input-error-style margin-top4"}
                  showError={error?.Pan ? error.Pan : ""}
                  errorIcon={errorInfoIcon}
                  name={"Pan"}
                  maxLength={10}
                  value={props?.dataObj?.["Pan"]}
                  onChange={onChange}
                  className={isMobile ? "" : "width-100"}
                  marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
                  verifyText={fishEyeIcon}
                  showVerifyText={true}
                />
              </>
            )}
            {(props?.dataObj?.docChosen === "Gst" ||
              props?.dataObj?.docChosen === "Pan") && (
              <>
                <InputFieldWithTitle
                  showVerifyText={tanVerified || verifyLoader ? false : true}
                  titleClassName={` ${
                    isMobile
                      ? `font-weight500 font-size14 line-height16 ${style["color333333"]}`
                      : "font-size16 line-height19 font-weight600 colorDark9"
                  } letterSpacing05`}
                  title={"Enter TAN Number"}
                  disabled={false}
                  isCompulsory={false}
                  compulsoryStarClassName="line-height14"
                  inputClassName={`margin-top8   width-100 boxRadius4 padding12 ${
                    style["inputBorder"]
                  } font-weight400 ${
                    isMobile
                      ? "font-size14 line-height16"
                      : "font-size16 line-height19"
                  } letterSpacing001 ${
                    showTanNumber ? "" : style["pox-password"]
                  }`}
                  disableInputClassName={`input-style-disable`}
                  errorInputClassName={"input-style-error"}
                  errorClassName={"input-error-style margin-top4"}
                  showError={error?.Tan ? error.Tan : ""}
                  errorIcon={errorInfoIcon}
                  name={"Tan"}
                  maxLength={10}
                  value={props?.dataObj?.["Tan"]}
                  onChange={onChange}
                  className={isMobile ? "" : "width-100"}
                  marginCSS={"margin-bottom8"}
                  isVerified={tanVerified}
                  verifiedClassName={style["input-verified"]}
                  verifyClicked={handleVerifyTan}
                  showVerifiedCheckIcon={tanVerified}
                  verifyLoaderClass={style["verify-loader-style"]}
                  showVerifyTextClassName={style["to-verify-email-text"]}
                  verifyLoader={verifyLoader}
                />
                <div
                  className={`d-flex align-items-center gap8 ${
                    isMobile ? "margin-bottom16" : "margin-bottom24"
                  } `}
                >
                  <CustomCheckBox
                    id="show-tan-switch"
                    className="mb-auto"
                    inputProps={{
                      name: "show-tan-switch",
                      checked: showTanNumber,
                      onChange: () => setShowTanNumber(!showTanNumber),
                    }}
                  />
                  <HeadLines
                    className={`${
                      isMobile
                        ? `font-weight500 font-size14 line-height16 ${style["color333333"]}`
                        : "font-size16 line-height19 font-weight600 colorDark9"
                    } letterSpacing05`}
                    text={`Show TAN Number`}
                  />
                  {/* <Switch
                      id="show-tan-switch"

                      checked={showTanNumber}
                      onChange={() => setShowTanNumber(!showTanNumber)}

                    /> */}
                </div>
                <div
                  className={`${
                    !isMobile && `${style["desk-radiobtn"]} flex-column`
                  } margin-bottom16`}
                >
                  <HeadLines
                    text={"Are you MSME registered client?"}
                    className={` ${
                      isMobile
                        ? `font-weight500 font-size14 line-height16 ${style["color333333"]}`
                        : "font-size16 line-height19 font-weight600 colorDark9"
                    } letterSpacing05`}
                  />
                  <div className={`d-flex gap16 margin-top8 `}>
                    <div className={`d-flex align-items-center gap8`}>
                      <CustomRadioBtn
                        inputProps={{
                          name: "wh",
                          // disabled: isAryaWareHouseDisabled,
                          checked: radioCheckMsme === MSME_TYPE.YES,
                          value: radioCheckMsme,
                          onChange: (e) => {
                            setRadioCheckMsme(MSME_TYPE.YES);
                          },
                        }}
                        id="awh"
                      />
                      <label className="m-0" htmlFor="awh">
                        <HeadLines
                          className={
                            isMobile
                              ? `font-weight400 font-size14 line-height16 letterSpacing005 colorDark8`
                              : style["desk-radio-btn-text"]
                          }
                          text="Yes"
                        />
                      </label>
                    </div>
                    <div className={"d-flex align-items-center gap8"}>
                      <CustomRadioBtn
                        inputProps={{
                          name: "wh",
                          onChange: (e) => {
                            // setWarehouseType(e.target.value);
                            // clearForm();
                            setRadioCheckMsme(MSME_TYPE.NO);
                            setMSMEError("");
                            setData((prev) => ({
                              ...prev,
                              ["msme_certificate"]: null,
                            }));
                          },
                          checked: radioCheckMsme === MSME_TYPE.NO,
                          value: radioCheckMsme,
                        }}
                        id="nawh"
                      />
                      <label className="m-0" htmlFor="nawh">
                        <HeadLines
                          className={
                            isMobile
                              ? `font-weight400 font-size14 line-height16 letterSpacing005 colorDark8`
                              : style["desk-radio-btn-text"]
                          }
                          text="No"
                        />
                      </label>
                    </div>
                  </div>
                  {radioCheckMsme === "1" && (
                    <div>
                      <UploadDocumentComponent
                        className={`${
                          isMobile ? "margin-top16" : "margin-top10"
                        } width-100`}
                        customTextClassName={`${style["text-truncate-file-upload"]} text-truncate`}
                        onChange={(e) => {
                          handleImageChange(e);
                        }}
                        name={"msme"}
                        value={data?.msme_certificate?.name}
                        placeholder={
                          data?.msme_certificate?.name ??
                          "Upload MSME Certificate"
                        }
                        inputClassName={`width-100 boxRadius4 padding12 ${
                          style["input-border"]
                        } font-weight400 ${
                          isMobile
                            ? "font-size14 line-height16 letterSpacing001 margin-top8 "
                            : "font-size16 line-height19 letterSpacing05 margin-top4"
                        }`}
                        inputParentClassName={
                          isMobile ? " margin-top8  " : " margin-top4 "
                        }
                        errorInputClassName={"input-style-error"}
                        errorClassName={"input-error-style margin-top4"}
                        title={"Upload MSME Certificate"}
                        // showError={msmeError}
                        // errorIcon={errorInfoIcon}
                        titleClassName={`${
                          isMobile
                            ? `font-size14 line-height16 font-weight500 ${style["color333333"]}`
                            : "font-size16 line-height19 font-weight600 colorDark9"
                        } letterSpacing05`}
                        compulsoryStarClassName={style["margin-top4px"]}
                        isCompulsory={true}
                        financePage={false}
                        // kycForm={true}
                      />
                      {msmeError && (
                        <div className="d-flex align-items-center padding-top4">
                          {errorInfoIcon && (
                            <img
                              src={errorInfoIcon}
                              className={`${style["errorCSS"]} ${
                                style["error-icon-style"]
                              } display-inline-block  ${
                                isMobile ? "" : style["width18px"]
                              }`}
                              alt=""
                            />
                          )}
                          <HeadLines
                            className={`${
                              style["errorCSS"]
                            } input-error-style font-size12 display-inline-block padding-left4 ${
                              isMobile ? "" : `${style["desktopCssImp"]} `
                            }`}
                            text={translation(`${msmeError}`)}
                          ></HeadLines>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
            <InputFieldWithTitle
              titleClassName={`${
                isMobile
                  ? `font-weight500 font-size14 line-height16 ${style["color333333"]}`
                  : "font-size16 line-height19 font-weight600 colorDark9"
              } letterSpacing05`}
              title={"Email Address"}
              disabled={false}
              isCompulsory={true}
              compulsoryStarClassName="line-height14"
              inputClassName={`margin-top8   width-100 boxRadius4 padding12 ${
                style["inputBorder"]
              } font-weight400 ${
                isMobile
                  ? "font-size14 line-height16"
                  : "font-size16 line-height19"
              } letterSpacing001`}
              errorInputClassName={"input-style-error"}
              errorClassName={"input-error-style margin-top4"}
              showError={error?.email ? error.email : ""}
              errorIcon={errorInfoIcon}
              value={data.email}
              name="email"
              onChange={onChange}
              marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
              // showVerifyText="Verify"
              // showVerifyTextClassName={style["to-verify-email-text"]}
              // rightInputFixedTextClassName={style["to-verify-email-text"]}
            />
            <InputFieldWithTitle
              titleClassName={`${
                isMobile
                  ? `font-weight500 font-size14 line-height16 ${style["color333333"]}`
                  : "font-size16 line-height19 font-weight600 colorDark9"
              } letterSpacing05`}
              title={"Pincode"}
              disabled={false}
              isCompulsory={true}
              compulsoryStarClassName="line-height14"
              inputClassName={`margin-top8   width-100 boxRadius4 padding12 ${
                style["inputBorder"]
              } font-weight400 ${
                isMobile
                  ? "font-size14 line-height16"
                  : "font-size16 line-height19"
              } letterSpacing001`}
              errorInputClassName={"input-style-error"}
              errorClassName={"input-error-style margin-top4"}
              showError={error?.pincode ? error.pincode : ""}
              errorIcon={errorInfoIcon}
              value={data.pincode}
              name="pincode"
              onChange={onChange}
              marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
            />
            <div className={`d-flex ${isMobile ? "gap8" : "width-100"}`}>
              <InputFieldWithTitle
                showVerifyText={false}
                titleClassName={`${
                  isMobile
                    ? `font-weight500 font-size14 line-height16 ${style["color333333"]}`
                    : "font-size16 line-height19 font-weight600 colorDark9"
                } letterSpacing05`}
                title={"City / District"}
                disabled={true}
                isCompulsory={true}
                compulsoryStarClassName="line-height14"
                inputClassName={`margin-top8   width-100 boxRadius4 padding12 ${
                  style["inputBorder"]
                } font-weight400 ${
                  isMobile
                    ? "font-size14 line-height16"
                    : "font-size16 line-height19"
                } letterSpacing001`}
                disableInputClassName={`input-style-disable`}
                errorInputClassName={"input-style-error"}
                errorClassName={"input-error-style margin-top4"}
                showError={error?.city ? error.city : ""}
                errorIcon={errorInfoIcon}
                value={data.city}
                name="city"
                className={isMobile ? "" : "width-100"}
                marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
              />
              <InputFieldWithTitle
                showVerifyText={false}
                titleClassName={`${
                  isMobile
                    ? `font-weight500 font-size14 line-height16 ${style["color333333"]}`
                    : "font-size16 line-height19 font-weight600 colorDark9"
                } letterSpacing05`}
                title={"State"}
                disabled={true}
                isCompulsory={true}
                compulsoryStarClassName="line-height14"
                inputClassName={`margin-top8   width-100 boxRadius4 padding12 ${
                  style["inputBorder"]
                } font-weight400 ${
                  isMobile
                    ? "font-size14 line-height16"
                    : "font-size16 line-height19"
                } letterSpacing001`}
                disableInputClassName={`input-style-disable`}
                errorInputClassName={"input-style-error "}
                errorClassName={"input-error-style margin-top4"}
                showError={error?.state ? error.state : ""}
                errorIcon={errorInfoIcon}
                value={data.state}
                name="state"
                className={isMobile ? "" : "width-100 margin-left20"}
                marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
              />
            </div>
            <TextAreaWithTitle
              titleClassName={`${
                isMobile
                  ? `font-weight500 font-size14 line-height16 ${style["color333333"]}`
                  : "font-size16 line-height19 font-weight600 colorDark9"
              } letterSpacing05`}
              title="Communication Address"
              showWordCount
              isCompulsory={true}
              compulsoryStarClassName="line-height14"
              // inputClassName={`${styles["input-style"]}  margin-top5`}
              // disableInputClassName={`${styles["input-style-disable"]}`}
              errorInputClassName={`input-style-error`}
              errorClassName={
                isMobile
                  ? `input-error-style `
                  : style["desk-input-error-style"]
              }
              errorIconClassName={`margin-right4 ${
                !isMobile && style["desk-info-error-icon"]
              } `}
              showError={error?.address ? error.address : ""}
              errorIcon={errorInfoIcon}
              minLength={10}
              isLengthParenthesis={false}
              countClassName={textAreaStyles["word-count"]}
              textAreaClassName={`${textAreaStyles["textarea-style"]} ${style["height-80"]} margin-top5 margin-top8  `}
              textAreaProps={{
                placeholder: `${translation("Type Text Here....")}`,
                name: "address",
                value: data.address,
                onChange: onChange,
                maxLength: 300,
              }}
            />
          </>
        ) : (
          <>
            <div className={isMobile ? "" : "d-flex width-100"}>
              <div className="width-100">
                <UploadDocumentComponent
                  className={isMobile ? "margin-top16" : "width-100"}
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                  value={data?.logo?.name}
                  placeholder={data?.logo?.name || "Select Bank Logo"}
                  inputClassName={`width-100 boxRadius4 padding12 ${
                    style["input-border"]
                  } font-weight400 ${
                    isMobile
                      ? "font-size14 line-height16"
                      : "font-size16 line-height19"
                  } letterSpacing001`}
                  inputParentClassName={
                    isMobile ? " margin-top8  " : " margin-top4 "
                  }
                  errorInputClassName={"input-style-error"}
                  errorClassName={"input-error-style"}
                  title={"Upload Bank Logo"}
                  // showError={error?.logo ? error.logo : ""}
                  // errorIcon={errorInfoIcon}
                  titleClassName={`colorDark9 ${
                    isMobile
                      ? "font-weight700 font-size12 line-height14"
                      : "font-size16 line-height19 font-weight600"
                  } letterSpacing025`}
                  compulsoryStarClassName={style["margin-top4px"]}
                  isCompulsory={true}
                  financePage={false}
                />

                {error?.logo && (
                  <div className="d-flex align-items-center padding-top4">
                    {errorInfoIcon && (
                      <img
                        src={errorInfoIcon}
                        className={`${style["errorCSS"]} ${
                          style["error-icon-style"]
                        } display-inline-block  ${
                          isMobile ? "" : style["width18px"]
                        }`}
                        alt=""
                      />
                    )}
                    <HeadLines
                      className={`${
                        style["errorCSS"]
                      } input-error-style font-size12 display-inline-block padding-left4 ${
                        isMobile ? "" : `${style["desktopCssImp"]} `
                      }`}
                      text={
                        translation(`${error?.logo}`)

                        // <>
                        //   {errorIcon && (
                        //     <img
                        //       src={errorIcon}
                        //       className={`${errorIconClassName}`}
                        //       alt=""
                        //     />
                        //   )}
                        //   {showError}
                        // </>
                      }
                    ></HeadLines>
                  </div>
                )}
                <HeadLines
                  text="Please upload a jpeg/png/pdf. Maximum file size limit is 2MB."
                  className={` font-weight400 ${
                    isMobile
                      ? "font-size10 line-height12 margin-top16 "
                      : "font-size14 line-height16 margin-top8 "
                  } letterSpacing025 colorDark7`}
                />
              </div>

              {/* <UploadDoc
            titleClassName={`colorDark9 font-weight700 font-size12 line-height14 letterSpacing025`}
            title={"Upload Bank Logo"}
            inputClassName={`width-100 boxRadius4 padding12 ${style["input-border"]} font-weight400 font-size14 line-height16 letterSpacing001`}
            inputParentClassName=" margin-top8  "
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style"}
            showError={error?.logo ? error.logo : ""}
            errorIcon={errorInfoIcon}
            value=""
            name="logo"
            onChange={(e) => {
              handleImageChange(e);
            }}
          /> */}
              <div
                className={
                  isMobile ? "margin-top16" : "margin-left20 width-100"
                }
              >
                <div className="d-flex">
                  <HeadLinesSpan
                    text="Enter average Turn Around Time to make loan offer"
                    className={`colorDark9 ${
                      isMobile
                        ? "font-weight700 font-size12 line-height14 padding-top12"
                        : "font-size16 line-height19 font-weight600"
                    } letterSpacing025 `}
                  />{" "}
                  <HeadLinesSpan
                    text="*"
                    className={`asterisk ${style["margin-top4px"]}`}
                  />
                </div>
                <CustomReactSelect
                  // styles={customStyles}
                  className={`lightBorder  boxRadius4 ${
                    style["textColorBlack"]
                  }  ${
                    isMobile
                      ? "margin-top8"
                      : `${style["height43"]} margin-top4 ${style["height43Imp"]}`
                  }`}
                  options={tatArray}
                  onChange={(e) =>
                    onChange({ target: { name: "tat", value: e } })
                  }
                  value={data.tat}
                />
              </div>
            </div>

            <div className={isMobile ? "" : "margin-top24"}>
              <HeadLinesSpan
                text="Enter Rate of Interest to make loan offer (in %)"
                className={`colorDark9 ${
                  isMobile
                    ? "font-weight700 font-size12 line-height14"
                    : "font-size16 line-height19 font-weight600"
                } letterSpacing025 margin-top16`}
              />
              <HeadLinesSpan
                text="*"
                className={`asterisk ${style["margin-top4px"]}`}
              />
            </div>
            <div className="d-flex">
              <InputFieldWithTitle
                // titleClassName={`${style["color333333"]}  font-weight500 font-size14 line-height16 letterSpacing05`}
                // title={""}
                disabled={false}
                inputClassName={`margin-top8   width-100 boxRadius4 padding12 ${
                  style["inputBorder"]
                } font-weight400 ${
                  isMobile
                    ? "font-size14 line-height16"
                    : "font-size16 line-height19"
                } letterSpacing001`}
                errorInputClassName={"input-style-error"}
                errorClassName={"input-error-style"}
                // errorIconClassName={"mb-auto"}
                showError={error?.roi_from ? error.roi_from : ""}
                errorIcon={errorInfoIcon}
                value={data.roi_from}
                name="roi_from"
                type="number"
                onChange={onChange}
                className={isMobile ? "" : style["width235"]}
                placeholder={"FROM"}
              />
              <InputFieldWithTitle
                // titleClassName={`${style["color333333"]}  font-weight500 font-size14 line-height16 letterSpacing05`}
                // title={"Email Address"}
                disabled={false}
                inputClassName={`margin-top8   width-100 boxRadius4 padding12 ${
                  style["inputBorder"]
                } font-weight400 ${
                  isMobile
                    ? "font-size14 line-height16 margin-left8"
                    : "font-size16 line-height19"
                } letterSpacing001`}
                errorInputClassName={"input-style-error"}
                errorClassName={"input-error-style"}
                showError={error?.roi_to ? error.roi_to : ""}
                errorIcon={errorInfoIcon}
                value={data.roi_to}
                name="roi_to"
                type="number"
                onChange={onChange}
                className={isMobile ? "" : `margin-left20 ${style["width235"]}`}
                placeholder={"TO"}
              />
            </div>
          </>
        )}
        {errorMessage && (
          <div className={isMobile ? "margin-top4" : "margin-top12"}>
            {" "}
            <ErrorMessage text={errorMessage} />{" "}
          </div>
        )}
        <SkipSubmitButton
          setBlurBackGround={props.setBlurBackGround}
          submitClick={
            stepNo == 1 || props.dataObj.userType != "Banks & NBFC’s"
              ? continueClicked
              : submitClick
          }
          buttonEnable={true}
          noSkip={true}
          buttonText={
            stepNo == 1 && props.dataObj.userType == "Banks & NBFC’s"
              ? "Continue"
              : "Submit"
          }
        />
      </div>
    </>
  );
};
export default AddDetails;
