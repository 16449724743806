import React from "react";
import styles from "./multiProgress.module.css";

const MultiProgressbar = ({
  totalLimit = 500000,
  stpLimit = 300000,
  ltpLimit = 200000,
  utilizedLimit = 0,
}) => {
  const getPercentage = (value) => (value / totalLimit) * 100;

  const stpPercentage = getPercentage(stpLimit);
  const ltpPercentage = getPercentage(ltpLimit);
  const utilizedPercentage = getPercentage(utilizedLimit);

  return (
    <div className={`${styles.container} margin-top12`}>
      <div className={styles.row}>
        <div className={styles.barContainer}>
          <div className={styles.fullBar}>
            <div
              className={`${styles.limitBar} ${styles.stpBar}`}
              style={{ width: `${stpPercentage}%` }}
            />
            <div
              className={`${styles.limitBar} ${styles.ltpBar}`}
              style={{ width: `${ltpPercentage}%`, left: `${stpPercentage}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiProgressbar;
