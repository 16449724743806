import React, { useEffect, useRef, useState } from "react";
import HeadLines from "../../../../CommonComponents/HeadLines";
import InputFieldWithTitle from "../../../../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import CustomReactSelect from "../../../../CommonComponents/CustomReactSelect/CustomReactSelect";
import TextAreaWithTitle from "../../../../CommonComponents/TextAreaWithTitle/TextAreaWithTitle";
import { crossWhiteIcon, errorInfoIcon, sendIcon } from "../../../../icon_assets";
import style from "./getInTouchPopup.module.css";
import { isMobile } from "../../../../helperFunc";
import { getInTouch } from "../../../../../../../_services/Arya2.0/Homepage";
import { customToastr } from "../../../../CustomToastr";

function GetInTouchPopupForm() {
  const [formData, setFormData] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSendDisabled, setIsSendDisabled] = useState(true); // Set the initial state
  const touchInRef = useRef(null);

  const handleFormChange = (key, value) => {
    if (key === "phone" && value.length > 10) {
      return;
    }
    setFormData((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.email || !validateEmail(formData.email)) {
      newErrors.email = "A valid email is required";
    }
    if (!formData.phone || formData.phone.length !== 10) {
      newErrors.phone = "Phone must be 10 digits";
    }
    if (!formData.type) {
      newErrors.type = "Please select a help option";
    }
    if (formData.type?.value === "Others" && !formData.info) {
      newErrors.info = "Please provide more information";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Update isSendDisabled whenever formData changes
  useEffect(() => {
    const basicFieldsFilled =
      formData.name &&
      validateEmail(formData.email) &&
      formData.phone?.length === 10 &&
      formData.type;

    if (formData.type?.value === "Others") {
      setIsSendDisabled(!(basicFieldsFilled && formData.info));
    } else {
      setIsSendDisabled(!basicFieldsFilled);
    }
  }, [formData]); // This effect runs when formData changes

  const handleSendClick = async () => {
    if (validateForm()) {
      const dataObj = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        type: formData.type?.value,
        info: formData.info || "",
      };

      try {
        const response = await getInTouch(dataObj);
        if (response?.status_code === 200) {
          customToastr({
            type: "success",
            timeOut: 3000,
            text: "Form submitted successfully",
          });
          setOpenForm(false);
          setFormData({});
        }
      } catch (error) {
        customToastr({
          type: "warning",
          timeOut: 3000,
          text: error?.message,
        });
      }
    } else {
      console.log("Validation failed");
    }
  };

  useEffect(() => {
    if (!isMobile && openForm && touchInRef.current) {
      const offset = -100;
      const elementPosition =
        touchInRef.current.getBoundingClientRect().top + window.scrollY;
      const scrollToPosition = elementPosition + offset;
      window.scrollTo({ top: scrollToPosition, behavior: "smooth" });
    }
  }, [openForm]);

  useEffect(() => {
    if (!openForm) {
      setFormData({}); // Clear form data
    }
  }, [openForm]);

  const options = [
    {
      label: "Registration related help",
      value: "Registration related help",
    },
    {
      label: "Warehouse booking related help",
      value: "Warehouse booking related help",
    },
    {
      label: "Loan application related help",
      value: "Loan application related help",
    },
    {
      label: "Trade services related help",
      value: "Trade services related help",
    },
    {
      label: "Account related help",
      value: "Account related help",
    },
    {
      label: "Lodge a complaint",
      value: "Lodge a complaint",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  return !openForm ? (
    <div
      onClick={() => {
        setOpenForm(true);
      }}
      className={`cursorPointer stdFontStyle d-flex align-items-center justify-content-center
      ${
        isMobile
          ? `${style["positionMobFixed"]} ${style["closedMobileForm"]}`
          : `${style["positionAbsolute"]} ${style["closedForm"]} `
      }`}
    >
      <img src={sendIcon} alt="Send Icon" />
      <HeadLines
        text="Get in touch"
        className={`${
          isMobile
            ? "font-size12 line-height14 letterSpacing005"
            : "font-size14 line-height16 letterSpacing015"
        } textColorWhite font-weight500 margin-left4`}
      />
    </div>
  ) : (
    <div
      className={`${style["openForm"]} stdFontStyle 
      ${
        isMobile
          ? `${style["openMobFormFixed"]} ${style["whiteBackground"]}`
          : `${
              formData.type?.value === "Others"
                ? style["openFormAbsolute"]
                : style["openFormAbsoluteWithoutOther"]
            } ${style["width400"]}`
      }`}
      ref={touchInRef}
    >
      <div
        className={`d-flex cursorPointer justify-content-space-between align-items-center ${style["getInTouch"]} padding16`}
        onClick={() => setOpenForm(false)}
      >
        <HeadLines
          text="Get in touch"
          className={`${
            isMobile
              ? "font-size14 line-height16 letterSpacing005"
              : "font-size16 line-height19 letterSpacing015"
          } textColorWhite font-weight500 margin-left12`}
        />
        <img src={crossWhiteIcon} alt="Close Icon" />
      </div>

      <div className="d-flex flex-d-col align-items-center padding24">
        <HeadLines
          className={`${
            isMobile
              ? "font-size14 line-height16 letterSpacing005"
              : "font-size16 line-height19 letterSpacing015"
          } colorDark1 font-weight600`}
          text="We're here to help! Kindly let us know how we may help you?"
        />
        <InputFieldWithTitle
          isCompulsory
          errorIcon={errorInfoIcon}
          titleClassName={`${style["input-title1"]}`}
          title={"Name"}
          inputClassName={`${style["input-style"]}`}
          disableInputClassName={`${style["input-style-disable"]}`}
          className="width-100 margin-top16"
          placeholder="Enter name"
          marginCSS={isMobile && "NA"}
          value={formData.name}
          onChange={(e) => {
            handleFormChange("name", e.target.value);
          }}
          showError={errors.name} // Show error for name field
        />
        <InputFieldWithTitle
          isCompulsory
          errorIcon={errorInfoIcon}
          titleClassName={`${style["input-title1"]}`}
          className="width-100 margin-top16"
          title={"Email"}
          inputClassName={`${style["input-style"]} ${
            errors.email && style["redBorder"]
          }`}
          disableInputClassName={`${style["input-style-disable"]}`}
          placeholder="Enter Email"
          marginCSS={isMobile && "NA"}
          value={formData.email}
          onChange={(e) => {
            handleFormChange("email", e.target.value);
          }}
          // showError={errors.email} // Show error for email field
        />
        <InputFieldWithTitle
          isCompulsory
          errorIcon={errorInfoIcon}
          titleClassName={`${style["input-title1"]}`}
          className="width-100 margin-top16"
          title={"Phone"}
          inputClassName={`${style["input-style"]}`}
          disableInputClassName={`${style["input-style-disable"]}`}
          placeholder="Enter Phone"
          value={formData.phone}
          type="number"
          marginCSS={isMobile && "NA"}
          onChange={(e) => {
            handleFormChange("phone", e.target.value);
          }}
          // showError={errors.phone} // Show error for phone field
        />
        <div className="width-100 margin-top16">
          <HeadLines
            text={
              <div>
                How we can help you?<span className="asterisk"> *</span>
              </div>
            }
            className={`${
              isMobile
                ? "font-size14 line-height16 letterSpacing005"
                : "font-size16 line-height19 letterSpacing015"
            } colorDark9 font-weight500`}
          />
          <CustomReactSelect
            placeholder="Select "
            isClearable
            onChange={(e) => handleFormChange("type", e)}
            className={`${style["reactSelect"]} font-weight400 ${
              isMobile ? "font-size12" : "font-size16"
            } line-height14 letterSpacing025 margin-top4`}
            options={options}
            value={options.find(
              (option) => option.value === formData.type?.value
            )}
          />
          {/* {errors.type && <div className="textColorError">{errors.type}</div>}{" "} */}
          {/* Display select error */}
        </div>
        {formData?.type?.value === "Others" && (
          <TextAreaWithTitle
            titleClassName={`colorDark9 ${
              isMobile
                ? "font-size12 line-height14 font-weight700 letterSpacing025"
                : "font-weight600 letterSpacing005"
            }`}
            title="More info"
            className="width-100 margin-top12"
            isCompulsory
            titleParentClass="mt-auto"
            showWordCount={false}
            compulsoryStarClassName={`line-height14`}
            errorIconClassName={`${style["margin-right4"]} ${
              !isMobile && style["desk-info-error-icon"]
            }`}
            errorInputClassName={`input-style-error`}
            errorClassName={`${
              isMobile ? "input-error-style" : style["desk-input-error-style"]
            }`}
            textAreaProps={{
              placeholder: "Select",
              name: "more_info",
              onChange: (e) => {
                handleFormChange("info", e.target.value);
              },
              value: formData.info,
            }}
            errorIcon={errorInfoIcon}
            isLengthParenthesis={false}
            textAreaClassName={`${style["textarea-style"]}  margin-top5 margin-top4  `}
          />
        )}
        {/* {errors.info && <div className="textColorError">{errors.info}</div>}{" "} */}
        {/* Display info error */}
        <div
          onClick={handleSendClick}
          className={`margin-top20 ${style["sendButton"]} ${
            isSendDisabled ? "opacity50" : "cursorPointer"
          } d-flex align-items-center justify-content-center`}
        >
          <img src={sendIcon} alt="Send Icon" />
          <HeadLines
            text="Send"
            className={`${
              isMobile
                ? "font-size12 line-height14 letterSpacing005"
                : "font-size14 line-height16 letterSpacing015"
            } textColorWhite font-weight600 margin-left8`}
          />
        </div>
      </div>
    </div>
  );
}

export default GetInTouchPopupForm;
