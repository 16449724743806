import React from "react";
import style from "./box.module.css";
import HeadLines from "../../../../CommonComponents/HeadLines";
import { isMobile } from "../../../../helperFunc";
import ActiveLoanComponent from "../ActiveLoanComponent/ActiveLoanComponent";
function BoxWithLeftTitle({ title = "Wheat", children, className }) {
  return (
    <div className={`${style["backgroundBox"]} ${className}`}>
      <div className={`${style["titleBox"]}`}>
        <HeadLines
          text={title}
          className={`${
            isMobile
              ? "font-size14 line-height16 letterSpacing05"
              : "font-size16 line-height19"
          } font-weight500`}
        />{" "}
      </div>

      {children}
    </div>
  );
}

export default BoxWithLeftTitle;
