import React from "react";
import ReactSelect, { components } from "react-select";
import { crossIcon, downArrow } from "../../icon_assets";
import { memo } from "react";
import { isMobile } from "../../helperFunc";
const CustomReactSelect = memo((props) => {
  const { className, placeholder, outerStyles = {}, customComponents } = props;
  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <img src={crossIcon} alt="" />
      </components.MultiValueRemove>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={downArrow} alt="" />
      </components.DropdownIndicator>
    );
  };
  const IndicatorSeparator = ({ innerProps }) => {
    return <span />;
  };

  const customStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        borderBottom: "1px solid #EEEEEE",
        color: "#333333",
        backgroundColor: state.isFocused ? "#DFECE9" : "#fff",
        cursor: !isMobile && "pointer",
      };
    },
    multiValue: (base) => {
      return {
        ...base,
        backgroundColor: "#DFECE9",
        borderRadius: "40px",
        alignItems: "center",
        fontSize: 12,
      };
    },

    multiValueRemove: (base) => {
      return {
        ...base,
        height: "100%",
      };
    },
    control: (base) => {
      return {
        ...base,
        backgroundColor: "#FFFFFF",
        border: "1px solid #EEEEEE",
        outline: "none",
        boxShadow: "none",
        ":hover": { border: "1px solid #EEEEEE" },
      };
    },
    multiValueLabel: (base) => ({ ...base, fontSize: 12, color: "#0E5B48" }),
    input: (base) => ({ ...base, color: "#0E5B48", fontSize: 12 }),
    menu: (base) => ({ ...base, boxShadow: "unset" }),
    menuList: (base) => ({
      ...base,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1);",
      border: "1px solid #EEEEEE",
      borderRadius: "4px",
      padding: 0,
      maxHeight: isMobile ? "150px" : "300px",
    }),

    // indicatorContainer: (base) => {

    //     return({ ...base, padding: "15px 0px" })},
  };

  return (
    <>
      <ReactSelect
        isClearable={false}
        placeholder={placeholder || "Select"}
        components={{
          MultiValueRemove,
          DropdownIndicator,
          IndicatorSeparator,
          ...customComponents,
        }}
        styles={{ ...customStyles, ...outerStyles }}
        {...props}
        className={`${
          isMobile ? "font-size14 line-height16" : "font-size16 line-height19"
        } placeholderColorb8bdbd ${className}`}
      />
    </>
  );
});

export default CustomReactSelect;
