import React from "react";
import styles from "./myActiveLoanMobile.module.css";
import HeadLines from "../../../../CommonComponents/HeadLines";
import MultiProgressbar from "../MultiProgressBar/MultiProgressBar";

const MyActiveLoanMobile = ({
  commodity,
  serialNumber,
  disbursedAmount,
  quantityPledged,
  principalPaid,
  principalDue,
  quantityReleased,
  currentQtyPledged,
}) => {
  const progressPercentage =
    (principalPaid / (principalPaid + principalDue)) * 100;

  const commodityClasses = [
    "wheat",
    "maize",
    "urad",
    "soyabean",
    "cotton",
    "rice",
    "mustard",
    "millets",
    "gram",
    "redgram",
    "oats",
  ];

  const commodityClass = commodityClasses.includes(commodity?.toLowerCase())
    ? commodity?.toLowerCase()
    : "otherCommodity";

  return (
    <div className={`margin-top16 ${styles.container}`}>
      <div className={`${styles.commodityTag} ${styles[commodityClass]}`}>
        <HeadLines text={commodity} />
      </div>
      <HeadLines text={serialNumber} className={styles.serialNumber} />
      <div
        className={`d-flex align-items-center justify-content-space-between margin-top12`}
      >
        <div>
          <HeadLines
            text="Disbursed Amount"
            className={`font-size10 line-height12 font-weight400 colorAccent3`}
          />
          <HeadLines
            text={`₹ ${disbursedAmount.toLocaleString()}`}
            className={`font-size14 line-height16 font-weight500 colorAccent3`}
          />
        </div>
        <div className={styles.quantityPledged}>
          <HeadLines
            text="Qty. Pledged"
            className={`font-size10 line-height12 font-weight400 colorAccent3`}
          />

          <HeadLines
            text={`${quantityPledged}MT`}
            className={`font-size14 line-height16 font-weight500 colorAccent3`}
          />
        </div>
      </div>
      <div className={styles.progressBarContainer}>
        <MultiProgressbar ltpLimit={0} />
      </div>
      <div className={``}>
        <div className="d-flex align-items-center justify-content-space-between">
          <div>
            <HeadLines
              text="Principle Paid"
              className={`font-size10 line-height12 font-weight400 colorAccent3`}
            />
            <HeadLines
              text={`₹ ${principalPaid.toLocaleString()}`}
              className={`font-size12 line-height15 font-weight500 colorAccent3`}
            />
          </div>
          <div>
            <HeadLines
              text="Principle Due"
              className={`font-size10 line-height12 font-weight400 colorAccent3`}
            />
            <HeadLines
              text={`₹ ${principalDue.toLocaleString()}`}
              className={`font-size12 line-height15 font-weight500 colorAccent3`}
            />
          </div>
        </div>
      </div>
      <div
        className={`d-flex align-items-center justify-content-space-between margin-top12`}
      >
        <div>
          <HeadLines
            text="Quantity Released"
            className={`font-size10 line-height12 font-weight400 colorAccent3`}
          />
          <HeadLines
            text={`${quantityReleased}MT`}
            className={`font-size12 line-height15 font-weight500 colorAccent3`}
          />
        </div>
        <div>
          <HeadLines
            text="Current Qty. Pledged"
            className={`font-size10 line-height12 font-weight400 colorAccent3`}
          />
          <HeadLines
            text={`${currentQtyPledged}MT`}
            className={`font-size12 line-height15 font-weight500 colorAccent3 text-align-right`}
          />
        </div>
      </div>
    </div>
  );
};

export default MyActiveLoanMobile;
